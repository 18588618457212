export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.hasSearch = false

    tableRequestOption.url = '/sys/poolStandby/page'
    // formDialogOption.defaultItem = {status:1}

    // 删除参数
    tableDelDataOption.url = '/sys/poolStandby/delete'
    tableDelDataOption.type = 'bodyArr'

    // 资金池划转
    handleEventObj.exchangePool = (item)=>{
        drawerOption.show = true
        drawerOption.title = "资金池划转"
        drawerOption.item = item
        drawerOption.customCopName = 'exchangePool'
    }

    tableOption.exportOpt.fieldOption = [
        { name: '资金池名称', prop: 'name', checked: true },
        { name: '池内资金', prop: 'amount', checked: true },
        { name: '备注', prop: 'note', checked: true },
        { name: '创建日期', prop: 'createTime', checked: true },
    ]
}
