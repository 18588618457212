import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj
){
   
    formOption.updateApiOption.api = `/sys/rewardThank/addOrModify`
    formOption.labelWidth = 160
    formOption.newApiOption.api = `/sys/rewardThank/addOrModify`
}
