export const tableConfig=[
    {
        prop: "day",
        label: "日期",
    },
    {
        prop: "poolValue",
        label: "资金数",
    },
    {
        prop: "status",
        label: "分红状态",
        type:"state",
        dictType:'fund-pool-share-status'
    },
    {
        prop: "triggerFlag",
        label: "分红方式",
        type:"state",
        dictType:'fund-pool-share-type'
    },
    {
        prop: "poolValueYet",
        label: "已分资金数",
    },
    {
        prop: "poolValueSurplus",
        label: "剩余资金数",
    },
    {
        prop: "createTime",
        label: "创建日期",
    },
]

export const formConfig=[
    {
        prop: "day",
        label: "日期",
        type:'datetime',
        dtype:'date'
    },
    {
        prop: "poolValue",
        label: "资金数",
        type:'number',
        width:'60%'
    }
]

export const editFormConfig=[
    {
        prop: "poolValue",
        label: "资金数",
        type:'number',
        width:'60%'
    }
]