export const tableConfig=[
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "subTitle",
        label: "专区副标题",
    },
    {
        prop: "color",
        label: "文字颜色",
        type:"color"
    },
    {
        prop: "bgColor",
        label: "背景颜色",
        type:"color"
    },
    {
        prop: "btnBg",
        label: "按钮背景颜色",
        type:"color"
    },
    {
        prop: "rightTopBgColor",
        label: "热销商品右上角背景颜色",
        width:300,
        type:"color"
    },
    {
        prop: "icon",
        label: "图标",
        type:"img"
    },
    // {
    //     prop: "giveType",
    //     label: "专区赠送",
    //     type:"state",
    //     option:[
    //         {label:'不赠送',value:0},
    //         {label:'贡献值',value:1},
    //         {label:'绿色积分',value:2},
    //         {label:'购物券',value:3},
    //         {label:'刺梨果',value:4},
    //         // {label:"现金",value:5},
    //         {label:"ZZH",value:6},
    //     ]
    // },
    // {
    //     prop: "payType",
    //     label: "专区付款方式",
    //     type:"state",
    //     option:[
    //         {label:'在线支付',value:1},
    //         {label:'购物券支付',value:2},
    //     ]
    // },
    {
        prop: "type",
        label: "专区标识",
    },
    {
        prop: "sort",
        label: "排序",
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        dictType:'enable-status',
    },
]

export const formConfig=[
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "subTitle",
        label: "专区副标题",
    },
    {
        prop: "color",
        label: "文字颜色",
        type:'colorPicker',
    },
    {
        prop: "bgColor",
        label: "背景颜色",
        type:'colorPicker',
    },
    {
        prop: "btnBg",
        label: "按钮背景颜色",
        explain:'渐变色写法示例：linear-gradient(180deg, #FCA720 0%, #F88C08 100%)；非渐变写法示例：#FCA720'
    },
    {
        prop: "rightTopBgColor",
        label: "右上角背景颜色",
        explain:'渐变色写法示例：linear-gradient(180deg, #FCA720 0%, #F88C08 100%)；非渐变写法示例：#FCA720'
    },
    {
        prop: "icon",
        label: "图标",
        type:'img',
        tips:'建议尺寸：80*70'
    },
    // {
    //     prop: "giveType",
    //     label: "专区赠送",
    //     type:"select",
    //     option:[
    //         {label:'不赠送',value:0},
    //         {label:'贡献值',value:1},
    //         {label:'绿色积分',value:2},
    //         {label:'购物券',value:3},
    //         {label:'刺梨果',value:4},
    //         // {label:"现金",value:5},
    //         {label:"ZZH",value:6},
    //     ]
    // },
    // {
    //     prop: "payType",
    //     label: "专区付款方式",
    //     type:"select",
    //     option:[
    //         {label:'在线支付',value:1},
    //         {label:'购物券支付',value:2},
    //     ]
    // },
    {
        prop: "type",
        label: "专区标识",
        disabled:true,
    },
    {
        prop: "sort",
        label: "排序",
        type:"number"
    },
    {
        prop: "status",
        label: "状态",
        type:"radio",
        dictType:'enable-status',
    },
]