import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj
){
    formOption.labelWidth = 150
    formOption.newApiOption.api = `/sys/rewardConditionSet/addOrModify`
    // formOption.updateApiOption.api = `/sys/yunke/mgr/updateAdminInfo`

    getUserLevel()
    async function getUserLevel(){
        const res = await _axios('post','/sys/userLevel/list',{type:3},true)
        formColumnConfig[0].option = res
        formColumnConfig[0].lkey = 'levelName'
        formColumnConfig[0].vkey = 'levelId'
    }
    
    getAreaList()
    async function getAreaList(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        formColumnConfig[1].option = res
        formColumnConfig[1].lkey = 'regionName'
        formColumnConfig[1].vkey = 'id'
    }
}
