import { showConfirm } from "@/common/showConfirm"
import _axios from "@/plugins/axios"
import exportExcel from "@/common/exportExcel"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'账号/手机号码/姓名',prop:'account',type:'text'},
        {label:'等级类型',prop:'levelType',type:'select',options:[
            {label:"代理等级",value:1},
            {label:"拼券VIP等级",value:2},
            {label:"会员等级",value:3},
        ]},
        {label:'操作类型',prop:'operationType',type:'select',options:[
            {label:"变更",value:0},
            {label:"删除",value:1},
        ]},
        {label:'审核状态',prop:'status',type:'select',options:[
            {label:"未审核",value:0},
            {label:"审核成功",value:1},
            {label:"审核失败",value:2},
        ]}
    ]

    tableRequestOption.url = '/sys/userLevelUpdateExamine/page'

     //导出当前页
     handleEventObj.exportCurrent = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/userLevelUpdateExamine/exportExcel',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/userLevelUpdateExamine/exportExcel',
            fieldOption:[
                { name: '用户姓名', prop: 'realName', checked: true },
                { name: '手机号码', prop: 'userMobile', checked: true },
                { name: '账号', prop: 'account', checked: true },
                { name: '等级名称', prop: 'levelName', checked: true },
                { name: '等级类型', prop: 'levelType', checked: true },
                { name: '操作类型', prop: 'operationType', checked: true },
                { name: '变更前等级名称', prop: 'beforeLevelName', checked: true },		
                { name: '发起人', prop: 'createBy', checked: true },
                { name: '审核人', prop: 'updateBy', checked: true },
                { name: '审核状态', prop: 'status', checked: true },
                { name: '创建时间', prop: 'createTime', checked: true }
            ]
        }
    }

    //审核等级
    handleEventObj.checkLevel = (item)=>{
        showConfirm('是否通过审核？',{
           option:{
                confirmTitle:'通过',
                closeTitle:'拒绝',
           },
            confirm:()=>{
                checkReq(item,1)
            },
            cancel:()=>{
                checkReq(item,2)
            }
        })
    }

    async function checkReq(item,status){
        await _axios('post',`/sys/userLevelUpdateExamine/examine?id=${item.id}&status=${status}`,{},true)
        customBtnEvent.refreshList()
    }
}
