export const tableConfig=[
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "minLevelName",
        label: "最低等级名称",
    },
    {
        prop: "rewardType",
        label: "奖励类型",
        type:"state",
        dictType:'handle-style',
    },
]

export const formConfig=[
    {
        prop: "minLevelId",
        label: "最低等级名称",
        type:'select',
        option:[]
    },
    {
        prop: "regionId",
        label: "专区名称",
        type:"select",
        option:[]
    },
    {
        prop: "rewardType",
        label: "奖励类型",
        type:"select",
        dictType:'handle-style',
    },
]