export const tableConfig=[
    {
        prop: "transName",
        label: "模板名称",
        width:'300'
    },
    {
        prop: "chargeType",
        label: "计费方式",
        type:"state",
        width:'200',
        dictType:'charging-type',
    },
    {
        prop: "hasFreeCondition",
        label: "是否含有包邮条件",
        type:"state",
        width:'200',
        dictType:'mail-style',
    },
    // {
    //     prop: "isFreeFee",
    //     label: "是否包邮",
    //     type:"state",
    //     width:'200',
    //     option:[
    //         {label:"不包邮",value:0},
    //         {label:"包邮",value:1},
    //     ] 
    // },
    {
        prop: "createTime",
        label: "创建时间",
    },
]