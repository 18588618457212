export const tableConfig = [
    {
        prop: "userName",
        label: "用户名称",
        fixed:true
    },
    {
        prop: "userMobile",
        label: "电话号码",
        fixed:true
    },
    {
        prop: "account",
        label: "账号",
    },
    {
        prop: "integralType",
        label: "积分类型",
        type:'state',
        dictType:'integral-style'
    },
    {
        prop: "beforeIntegralValue",
        label: "修改前积分值",
    },
    {
        prop: "changeIntegralValue",
        label: "变动积分值",
    },
    {
        prop: "afterIntegralValue",
        label: "修改后积分值",
    },
    {
        prop: "changeUser",
        label: "修改人",
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
