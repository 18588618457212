export const tableConfig=[
    {
        prop: "rewardIntegralRate",
        label: "奖励积分比例(%)",
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:'state',
        dictType:'integral-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "rewardIntegralRate",
        label: "奖励积分比例(%)",
        type:"number"
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:'select',
        dictType:'integral-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]