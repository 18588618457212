export const tableConfig=[
    {
        prop: "payWayType",
        label: "支付方式",
        type: "state",
        dictType:'pay-type'
        // 支付方式 1微信支付H5 2购物券 3余额支付 4银行卡支付 5支付宝支付H5 6支付宝app 7微信app
    },
    {
        prop: "seq",
        label: "排序",
    },
    {
        prop: "icon",
        label: "图标",
        type:"img"
    },
    {
        prop: "defaultFlag",
        label: "是否默认支付方式",
        type: "state",
        dictType:'default-status',
    },
    {
        prop: "commissionCharge",
        label: "手续费(%)",
    },
    {
        prop: "staus",
        label: "状态",
        type: "state",
        dictType:'enable-status',
    }
]

export const formConfig=[
    {
        prop: "payWayType",
        label: "支付方式",
        type: "select",
        dictType:'pay-type'
        // 支付方式 1微信支付H5 2购物券 3余额支付 4银行卡支付 5支付宝支付H5 6支付宝app 7微信app
    },
    {
        prop: "icon",
        label: "图标",
        type:"img"
    },
    {
        prop: "seq",
        label: "排序",
        type: "number",
    },
    {
        prop: "defaultFlag",
        label: "设为默认",
        type: "select",
        dictType:'default-status',
    },
    {
        prop: "commissionCharge",
        label: "手续费(%)",
        type: "number",
        required:false
    },
    {
        prop: "staus",
        label: "状态",
        type: "select",
        dictType:'enable-status',
    }
]