export const tableConfig=[
    {
        prop: "commodityName",
        label: "商品名称",
        width:'300',
        fixed:true
    },
    {
        prop: "regionName",
        label: "所属专区",
        width:'300'
    },
    {
        prop: "deductIntegralFlag",
        label: "是否开启积分抵扣",
        type:"state",
        dictType:'open-deduction-status',
    },
    {
        prop: "categoryName",
        label: "商品分类",
        width:'300'
    },
    {
        prop: "commodityPic",
        label: "商品封面图",
        type:'img',
        width:'200'
    },
    {
        prop: "saleNum",
        label: "销量",
    },
    {
        prop: "isHot",
        label: "是否热销",
        type:"state",
        dictType:'hot-style',
    },
    {
        prop: "commodityStatus",
        label: "上架状态",
        type:"state",
        width:'200',
        dictType:'grounding-status'
    },
    {
        prop: "repurchaseFlag",
        label: "是否可复购",
        type:"state",
        dictType:'default-status',
    },
    {
        prop: "createTime",
        width:'250',
        label: "创建时间"
    }
]

