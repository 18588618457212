export const tableConfig=[
    {
        prop: "type",
        label: "兑换类型",
        type: "state",
        option:[ //1绿色积分兑换刺梨果 2
            {label:"绿色积分兑换刺梨果",value:2},
            {label:"ZZH兑换刺梨果",value:6},
        ]
    },
    {
        prop: "userRealName",
        label: "姓名",
    },
    {
        prop: "userMobile",
        label: "电话号码",
    },
    {
        prop: "provideType",
        label: "消耗的积分类型",
        type: "state",
        option:[
            {label:"贡献值",value:1},
            {label:"绿色积分",value:2},
            {label:"购物券",value:3},
            {label:"刺梨果",value:4},
            // {label:"现金",value:5},
            {label:"ZZH",value:6},
        ]
    },
    {
        prop: "provideNumber",
        label: "要兑换的积分数量",
    },
    {
        prop: "ratio",
        label: "兑换比例",
    },
    {
        prop: "getType",
        label: "得到的积分类型",
        type: "state",
        option:[
            {label:"贡献值",value:1},
            {label:"绿色积分",value:2},
            {label:"购物券",value:3},
            {label:"刺梨果",value:4},
            // {label:"现金",value:5},
            {label:"ZZH",value:6},
        ]
    },
    {
        prop: "getNumber",
        label: "兑换到的积分数量",
    },
    {
        prop: "status",
        label: "审批状态",
        type: "state",
        option:[{label:"待审批 ",value:1},{label:"同意",value:2},{label:"拒绝",value:3}]
    },
    {
        prop: "formatCheckTime",
        label: "申请时间",
    }
]

export const formConfig=[
    {
        prop: "status",
        label: "审批状态",
        type:"select",
        option:[
            {label:"同意",value:2},
            {label:"拒绝",value:3}
        ] 
    },
]