export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'模板名称',prop:'transName',type:'text'},
        {label:'计费方式',prop:'chargeType',type:'select',options:[
            {label:"按件",value:0},
            {label:"按重量",value:1},
            {label:"按体积",value:2}
        ]},
        {label:'是否含有包邮条件',prop:'hasFreeCondition',type:'select',options:[
            {label:"否",value:0},
            {label:"是",value:1},
        ]}, 
    ]

    tableRequestOption.url = '/sys/transport/page'

    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/transport/delete'
    tableDelDataOption.valkey = 'transportId'
    tableDelDataOption.type = 'bodyArr'
    
    //新建运费模板
    handleEventObj.newData = (item)=>{
        formDialogOption.formTitle = '运费模板--新建'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
        formDialogOption.dialogWidth = '60%'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'freightTmpAdd'
    }
    //编辑运费模板
    handleEventObj.editData = (item)=>{
        formDialogOption.formTitle = '运费模板--编辑'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
        formDialogOption.dialogWidth = '60%'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'freightTmpEdit'
    }
}
