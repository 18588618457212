export const tableConfig=[
    {
        prop: "rewardNum",
        label: "创业礼包奖励红包个数",
    },
    {
        prop: "minValue",
        label: "创业礼包最低价格",
    },
    {
        prop: "maxValue",
        label: "创业礼包最高价格",
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "rewardNum",
        label: "创业礼包奖励红包个数",
        type:'number'
    },
    {
        prop: "minValue",
        label: "创业礼包最低价格",
        type:'number'
    },
    {
        prop: "maxValue",
        label: "创业礼包最高价格",
        type:'number'
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]