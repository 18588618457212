export const tableConfig = [{
        prop: "userName",
        label: "用户名称",
    },
    {
        prop: "userPhone",
        label: "电话号码",
    },
    {
        prop: "payNo",
        label: "订单号",
    },
    {
        prop: "orderAmount",
        label: "订单金额",
    },
    {
        prop: "payStatus",
        label: "支付状态",
        type: "state",
        dictType:'pay-status',
    },
    {
        prop: "payAmount",
        label: "支付金额",
    },
    {
        prop: "payType",
        label: "支付方式",
        type: "state",
        dictType:'pay-type',
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
