export const tableConfig=[
    {
        prop: "integralType",
        label: "积分类型",
        type: "state",
        dictType:'integral-style'
    },
    {
        prop: "commissionStatus",
        label: "是否开启转账手续费",
        type: "state",
        option:[
            {label:"不开启",value:0},
            {label:"开启",value:1},
        ],
    },
    {
        prop: "commission",
        label: "手续费(%)",
    },
    {
        prop: "amountStatus",
        label: "是否开启转账限制",
        type: "state",
        option:[
            {label:"不限制",value:0},
            {label:"限制",value:1},
        ],
    },
    {
        prop: "ninAmount",
        label: "最低转账金额",
    },
    {
        prop: "maxAmount",
        label: "当日累计最大转账金额",
    },
    {
        prop: "status",
        label: "状态",
        type: "state",
        option:[
            {label:"启用",value:1},
            {label:"禁用",value:0},
        ]
    }
]

export const formConfig=[
    {
        prop: "integralType",
        label: "积分类型",
        type: "select",
        dictType:'integral-style'
    },
    {
        prop: "commissionStatus",
        label: "是否开启转账手续费",
        type: "select",
        option:[
            {label:"不开启",value:0},
            {label:"开启",value:1},
        ],
        explain:'若开启转账手续费，请在手续费一栏填写手续费',
        required:false
    },
    {
        prop: "commission",
        label: "手续费(%)",
        width:"100%",
        type:'number',
        explain:'单位%，例如：1%，则输入1',
        required:false
    },
    {
        prop: "amountStatus",
        label: "是否开启转账限制",
        type: "select",
        option:[
            {label:"不限制",value:0},
            {label:"限制",value:1},
        ],
        explain:'若开启转账限制，请在当日累计最大转账金额栏处填写相关限制金额',
        required:false
    },
    {
        prop: "maxAmount",
        label: "当日累计最大转账金额",
        width:"100%",
        type:'number',
        exp:/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/,
        tips:'当日累计最大转账金额必须大于0,小数最多2位',
        required:false
    },
    {
        prop: "ninAmount",
        label: "最低转账金额",
        width:"100%",
        type:'number',
        exp:/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/,
        tips:'最低转账金额必须大于0,小数最多2位',
    },
    {
        prop: "status",
        label: "状态",
        type: "select",
        option:[
            {label:"启用",value:1},
            {label:"禁用",value:0},
        ]
    }
]