export const tableConfig=[
    {
        prop: "commodityName",
        label: "商品名称",
        width:'300'
    },
    {
        prop: "categoryName",
        label: "商品分类",
        width:'300'
    },
    {
        prop: "commodityPic",
        label: "商品封面图",
        type:'img',
        width:'200'
    },
    // {
    //     prop: "type",
    //     label: "租卖类型",
    //     type:"state",
    //     width:'200',
    //     option:[
    //         {label:"只租",value:1},
    //         {label:"只卖",value:2},
    //         {label:"可租可卖",value:3},
    //     ] 
    // },
    {
        prop: "commodityStatus",
        label: "上架状态",
        type:"state",
        width:'200',
        dictType:'grounding-status',
    },
    {
        prop: "createTime",
        width:'250',
        label: "创建时间"
    }
]

