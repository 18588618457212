export const tableConfig=[
    // {
    //     prop: "directPercent",
    //     label: "直推奖比例(%)",
    // },
    // {
    //     prop: "indirectPercent",
    //     label: "间推奖比例(%)",
    // },
    {
        prop: "minAmount",
        label: "最低累计消费额",
    },
    // {
    //     prop: "levelId",
    //     label: "任务等级",
    //     type:"state",
    //     option:[]
    // },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status',
    }
]

export const formConfig=[
    // {
    //     prop: "directPercent",
    //     label: "直推奖比例(%)",
    //     type:"number",
    //     width:"60%",
    //     explain:'单位百分比(%),如：5%，输入5'
    // },
    // {
    //     prop: "indirectPercent",
    //     label: "间推奖比例(%)",
    //     type:"number",
    //     width:"60%",
    //     explain:'单位百分比(%),如：5%，输入5'
    // },
    {
        prop: "minAmount",
        label: "最低累计消费额",
        type:"number",
        width:"60%",
    },
    // {
    //     prop: "levelId",
    //     label: "任务等级",
    //     type:"select",
    //     option:[]
    // },
    {
        prop: "regionId",
        label: "专区类型",
        disabled:true,
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status',
    }
]