export const tableConfig=[
    {
        prop: "levelName",
        label: "级别名称",
    },
    {
        prop: "code",
        label: "级别标识",
    },
    // {
    //     prop: "directAmount",
    //     label: "奖励比例(%)",
    // },
    {
        prop: "status",
        label: "状态",
        type: "state",
        dictType:'enable-status',
    },  
    {
        prop: "createTime",
        label: "创建时间",
    }
]

export const formConfig=[
    {
        prop: "levelName",
        label: "级别名称",
    },
    {
        prop: "code",
        label: "级别标识",
        type: "select",
        disabled:true,
        option:[
            {label:0,value:0},
            {label:1,value:1},
            {label:2,value:2},
            {label:3,value:3},
        ]
    },
    // {
    //     prop: "directAmount",
    //     label: "奖励比例(%)",
    //     type:"number",
    //     explain:"单位百分比(%)，如5%，则输入5；0.1%，则输入0.1",
    //     width:"100%"
    // },
    {
        prop: "status",
        label: "状态",
        type: "select",
        dictType:'enable-status',
    }
]