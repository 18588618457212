export const tableConfig=[
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "addPercent",
        label: "增加百分比(%)",
    },
    {
        prop: "updateBy",
        label: "最后更改人",
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
]

export const formConfig=[
    {
        prop: "regionId",
        label: "专区名称",
        type:'select',
        option:[]
    },
    {
        prop: "addPercent",
        label: "增加百分比(%)",
        type:'number'
    }
]