export const tableConfig=[
    {
        prop: "rewardType",
        label: "奖励积分类型",
        type:"state",
        dictType:"integral-style"
    },
    {
        prop: "rewardRatio",
        label: "奖励积分比例(%)",
    },
    {
        prop: "redStatus",
        label: "是否消耗分红值",
        type:"state",
        dictType:"red-status"
    },
    {
        prop: "redRatio",
        label: "消耗分红值比例(%)",
    },
    // {
    //     prop: "lookVido",
    //     label: "是否观看视频",
    //     type:"state",
    //     dictType:"look-vido-status"
    // },
    // {
    //     prop: "lookVidoNum",
    //     label: "观看视频数量",
    // },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    },
    {
        prop: "createTime",
        label: "创建日期",
    },
]

export const formConfig=[
    {
        prop: "rewardType",
        label: "奖励积分类型",
        type:"select",
        dictType:"integral-style"
    },
    {
        prop: "rewardRatio",
        label: "奖励积分比例(%)",
        type:'number'
    },
    {
        prop: "redStatus",
        label: "是否消耗分红值",
        type:"select",
        dictType:"red-status"
    },
    {
        prop: "redRatio",
        label: "消耗分红值比例(%)",
        type:'number'
    },
    // {
    //     prop: "lookVido",
    //     label: "是否观看视频",
    //     type:"select",
    //     dictType:"look-vido-status"
    // },
    // {
    //     prop: "lookVidoNum",
    //     label: "观看视频数量",
    //     type:'number'
    // },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    },
]