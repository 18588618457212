import _axios from '@/plugins/axios';
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    //搜索
    searchOption.searchType='conditionQuery'
    searchOptionsConditionQuery.options = [
		{label:'商品名称',prop:'searchKey',type:'text'},
		{label:'商品分类',prop:'categoryId',type:'select',options:[],lkey:'categoryName',vkey:'categoryId'},
		{label:'所属专区',prop:'regionId',type:'select',options:[],lkey:'regionName',vkey:'id'},
        {label:'是否热销',prop:'isHot',type:'select',options:[
			{label:"否",value:0},
			{label:"是",value:1}
		]},
		{label:'上架状态',prop:'commodityStatus',type:'select',options:[
			{label:"下架",value:0},
			{label:"上架",value:1}
		]}
	]
	searchOptionsConditionQuery.options.map(async(v)=>{
		if(v.prop=='categoryId'){
			v.options = await getCategory()
		}
        if(v.prop=='regionId'){
            v.options = await getRegion()
        }
	})

    //设置新建表单默认值
    formDialogOption.defaultItem = {commodityStatus:1} 
    formDialogOption.dialogWidth = "40%"

    tableRequestOption.url = '/sys/commodity/page'
    tableRequestOption.params = {commodityPlatformType:1}

    //删除参数
    tableDelDataOption.method = 'get'
    tableDelDataOption.url = '/sys/commodity/delete'
    tableDelDataOption.key='prodId'
    tableDelDataOption.valkey='commodityId'
    
    //商品详情
    // handleEventObj.viewDetail = (item)=>{
    //     router.push({path:'/goods-detail',query:{productId:item.commodityId}})
    // }
    //商品编辑
    handleEventObj.editData = (item)=>{
        store.commit('page/setSubRoutParam',{path:route.path,page:{...pageParams},search:{...searchParams.value}})
        router.push({path:'/goods-edit',query:{productId:item.commodityId}})
    }
    handleEventObj.newData = (item)=>{
        router.push({path:'/goods-add'})
    }
    // 绑定专区
    handleEventObj.bindRegin = (item)=>{
        drawerOption.show = true
        drawerOption.size = '30%'
        drawerOption.title = "绑定专区"
        drawerOption.item = item
        drawerOption.customCopName = 'productBindRegin'
    }
}

//获取商品分类
async function getCategory(){
    const res = await _axios('get','/sys/category/listCategory',{},true)
    return res
}

//获取专区列表
async function getRegion(){
    const res = await _axios('post','/sys/productRegion/page',{
        pageNum: 1,
        pageSize: 100,
    },true)
    const {records} = res
    return records
}
