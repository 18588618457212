export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    searchOption.hasSearch = false

    tableOption.handleWidth = 300

    tableRequestOption.url = '/sys/productRegion/page'

     //删除参数
     tableDelDataOption.url = '/sys/productRegion/delete'
     tableDelDataOption.type = 'bodyArr'
   

    // handleEventObj.resetFormDialogWidth=()=>{
    //     formDialogOption.dialogWidth = "30%"
    // }

    // 奖励配置
    handleEventObj.rewardSet = (item)=>{
        router.push({path:'/specialArea/rewardSet',query:{
            title:item.regionName,
            id:item.id,
            content:'奖励配置',
            icon:'back',
            type:item.type
        }})
    }

    //专区产品
    handleEventObj.saProduct = (item)=>{
        router.push({path:'/specialArea/saProduct',query:{
            title:item.regionName,
            id:item.id,
            content:'商品列表',
            icon:'back',
            type:item.type
        }})
    }
    // ZZH配置
    handleEventObj.zzhSet = (item)=>{
        router.push({path:'/specialArea/zzhSet',query:{
            title:item.regionName,
            id:item.id,
            content:'ZZH配置',
            icon:'back',
            type:item.type
        }})
    }
}
