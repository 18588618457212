export const tableConfig = [{
    prop: "userName",
    label: "用户名称",
    fixed:true
},
{
    prop: "userMobile",
    label: "电话号码",
    fixed:true
},
{
    prop: "day",
    label: "期数",
    fixed:true
},
{
    prop: "account",
    label: "账号",
},
{
    prop: "getIntegralType",
    label: "获得积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "getIntegralValueShould",
    label: "应得积分数",
},
{
    prop: "getIntegralValueFact",
    label: "实得积分数",
},
{
    prop: "provideIntegralType",
    label: "消耗积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "provideIntegralValueShould",
    label: "应消耗积分数",
},
{
    prop: "provideIntegralValueFact",
    label: "实消耗积分数",
},
{
    prop: "createTime",
    label: "创建时间",
}
]
