import { createRouter, createWebHashHistory } from 'vue-router'
import {getToken} from '@/utils/tools.js'
import emitter from '@/plugins/mitt'

const routes= [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/',
        name: 'home',
        meta: {
            title: '后台管理系统',
            icon: 'education',
            roles: ['admin', 'editor']
        },
        component:() => import('@v/home.vue'),
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {
                    title: '主页',
                    icon: 'education',
                    type:'custom',
                    formType:'custom'
                },
                component: () => import('@v/index1.vue')
            },
            {
                path: '/system/:id',
                name: 'system',
                meta: {
                    title: '系统设置',
                    type:'default'
                },
                component: () => import('@c/generalList.vue')
            },
            {
			    path: '/product/:id',
			    name: 'product',
			    meta: {
			        title: '商品管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
                path: '/goods-detail',
                name: 'goods-detail',
                meta: {
                    title: '商品详情',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/goods-detail.vue')
            },
            {
                path: '/goods-category',
                name: 'goods-category',
                meta: {
                    title: '商品分类',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/goods-category.vue')
            },
            {
                path: '/goods-edit',
                name: 'goods-edit',
                meta: {
                    title: '商品编辑',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/goods-edit.vue')
            },
            {
                path: '/goods-add',
                name: 'goods-add',
                meta: {
                    title: '商品新增',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/goods-add.vue')
            },
            {
			    path: '/order/:id',
			    name: 'order',
			    meta: {
			        title: '订单管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
			    path: '/finance/:id',
			    name: 'finance',
			    meta: {
			        title: '财务管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
			    path: '/swiper/:id',
			    name: 'swiper',
			    meta: {
			        title: '轮播管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
			    path: '/specialArea/:id',
			    name: 'specialArea',
			    meta: {
			        title: '专区管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
                path: '/sa-goods-edit',
                name: 'sa-goods-edit',
                meta: {
                    title: '专区商品编辑',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/sa-goods-edit.vue')
            },
            {
                path: '/sa-goods-add',
                name: 'sa-goods-add',
                meta: {
                    title: '专区商品新增',
                    type:'custom',
					formType:'custom'
                },
                component: () => import('@c/customList/sa-goods-add.vue')
            },
            {
			    path: '/couponGame/:id',
			    name: 'couponGame',
			    meta: {
			        title: '拼券游戏',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
			    path: '/divided/:id',
			    name: 'divided',
			    meta: {
			        title: '分成管理',
			        type:'default',
					// formType:'custom'
			    },
			    component: () => import('@c/generalList.vue')
			},
            {
                path: '/menu',
                name: 'menu',
                meta: {
                    title: '菜单',
                    type:'custom',
                    formType:'custom'
                },
                component: () => import('@v/system/menu3.vue')
            },
			{
				path: '/userManager/:id',
				name: 'userManager',
				meta: {
				    title: '用户管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/zzh/:id',
				name: 'zzh',
				meta: {
				    title: 'ZZH管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/payType/:id',
				name: 'payType',
				meta: {
				    title: '支付管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/userManager/userTeam',
				name: 'userTeam',
				meta: {
				    title: '团队关系',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/customList/userTeam.vue')
			},
			{
				path: '/news/:id',
				name: 'news',
				meta: {
				    title: '新闻资讯',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/refund/:id',
				name: 'refund',
				meta: {
				    title: '售后管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/healthCoupon/:id',
				name: 'healthCoupon',
				meta: {
				    title: '健康购物券',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/reward/:id',
				name: 'reward',
				meta: {
				    title: '奖励管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/bigGift/:id',
				name: 'bigGift',
				meta: {
				    title: '创业礼包区管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/shopSet/:id',
				name: 'shopSet',
				meta: {
				    title: '爆款区管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/generalConsume/:id',
				name: 'generalConsume',
				meta: {
				    title: '普通消费区管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			{
				path: '/fundPool/:id',
				name: 'fundPool',
				meta: {
				    title: '资金池',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
			// {
			// 	path: '/third/:id',
			// 	name: 'third',
			// 	meta: {
			// 	    title: '测试三级菜单',
			// 	    type:'default',
			// 		// formType:'custom'
			// 	},
			// 	component: () => import('@c/generalList.vue')
			// },
			// {
			// 	path: '/exportGroupSelectUser',
			// 	name: 'exportGroupSelectUser',
			// 	meta: {
			// 	    title: '导出团队订单',
			// 	    type:'default',
			// 		// formType:'custom'
			// 	},
			// 	component: () => import('@c/customList/exportGroupSelectUser.vue')
			// },
            
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登陆'
        },
        component: () => import('@v/login.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@v/404.vue')
    },
    {
        path: '/upfiles/mimg',
        name: 'upfiles',
        meta: {
            title: '多图片上传',
            type:'default',
            // formType:'custom'
        },
        component: () => import('@v/upfilesImg.vue')
    },
]



const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {
    const token = getToken('token') || false
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //如果没登录,都导向登录页
    if (!token) {
        emitter.off('subMenuChange')
        emitter.off('menuClose')
        emitter.off('topBarChange')
        emitter.off('refreshTableList')
        emitter.off('changeSkin')
        if (to.path !== '/login') {
            next({ path: '/login' })
        }
        else {
            next();
        }
    }
    else {
        next();
    }
})

export default router
