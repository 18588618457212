export const tableConfig=[
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "content",
        label: "评论内容",
    },
    {
        prop: "score",
        label: "评分",
    },
    {
        prop: "evaluate",
        label: "评价",
        type:"state",
        option:[
            {label:"好评",value:0},
            {label:"中评",value:1},
            {label:"差评",value:2}
        ] 
    },
    {
        prop: "pics",
        label: "评论图片",
        type:"mimg"
    },
    {
        prop: "status",
        label: "是否显示",
        type:"state",
        option:[
            {label:"不显示",value:-1},
            {label:"显示",value:0},
        ] 
    },
    {
        prop: "recTime",
        label: "记录时间",
    }
]

export const formConfig=[
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "content",
        label: "评论内容",
    },
    {
        prop: "score",
        label: "评分",
    },
    {
        prop: "evaluate",
        label: "评价",
        type:"select",
        option:[
            {label:"好评",value:0},
            {label:"中评",value:1},
            {label:"差评",value:2}
        ] 
    },
    {
        prop: "pics",
        label: "评论图片",
        type:"mimg"
    },
    {
        prop: "postip",
        label: "IP来源",
    },
    {
        prop: "status",
        label: "是否显示",
        type:"select",
        option:[
            {label:"不显示",value:-1},
            {label:"显示",value:0},
        ] 
    },
    {
        prop: "isAnonymous",
        label: "是否匿名",
        type:"select",
        option:[
            {label:"是",value:1},
            {label:"否",value:0},
        ] 
    },
    {
        prop: "recTime",
        label: "记录时间",
    }
]