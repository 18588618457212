export const tableConfig=[
    {
        prop: "levelId",
        label: "赠送等级",
        type:'state',
        option:[]
    },
    {
        prop: "preLevelId",
        label: "购买前的等级",
        type:'state',
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "levelId",
        label: "赠送等级",
        type:'select',
        option:[]
    },
    {
        prop: "preLevelId",
        label: "购买前的等级",
        type:'select',
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]