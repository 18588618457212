import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.hasSearch = false

    tableRequestOption.url = '/sys/shoppingShareSetting/page'
    formDialogOption.defaultItem = {status:1}

    //删除参数
    tableDelDataOption.url = '/sys/shoppingShareSetting/delete'
    tableDelDataOption.type = 'bodyArr'

    // getUserLevelList()
    // async function getUserLevelList(){
    //     const res = await _axios('post','/sys/userLevel/list',{type:3},true)
    //     tableColumnConfig[2].option = res
    //     tableColumnConfig[2].lkey = 'levelName'
    //     tableColumnConfig[2].vkey = 'levelId'
    // }
}
