export const tableConfig=[
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "rewardIntegralPercent",
        label: "奖励积分百分比(%)",
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:"state",
        dictType:'integral-style',
    },
    {
        prop: "rewardType",
        label: "奖励类型",
        type:"state",
        dictType:'handle-style',
    },
]

export const formConfig=[
    {
        prop: "regionId",
        label: "专区名称",
        type:'select',
        option:[]
    },
    {
        prop: "rewardIntegralPercent",
        label: "奖励积分百分比(%)",
        type:'number'
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:"select",
        dictType:'integral-style',
    },
    {
        prop: "rewardType",
        label: "奖励类型",
        type:"select",
        dictType:'handle-style',
    },
]