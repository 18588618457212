import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'转出方手机/账号/名称',prop:'userPhone',type:'text'},
        {label:'接收方手机/账号/名称',prop:'toUserPhone',type:'text'},
        {label:'积分类型',prop:'integralType',type:'select',options:[
            {label:"贡献值",value:1},
            {label:"绿色积分",value:2},
            {label:"购物券",value:3},
            {label:"刺梨果",value:4},
            // {label:"现金",value:5},
            {label:"ZZH",value:6},
        ]},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    tableRequestOption.url = '/sys/userTransferAccountsRecord/page'

     //导出当前页
     handleEventObj.exportCurrent = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/userTransferAccountsRecord/exportExcel',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/userTransferAccountsRecord/exportExcel',
            fieldOption:[
                { name: '转出方手机号码', prop: 'userPhone', checked: true },
                { name: '转出方账号', prop: 'userAccount', checked: true },
                { name: '转出方名称', prop: 'userName', checked: true },
                { name: '接收方手机号码', prop: 'toUserPhone', checked: true },
                { name: '接收方账号', prop: 'toUserAccount', checked: true },
                { name: '接收方名称', prop: 'toUserName', checked: true },
                { name: '转账金额', prop: 'amount', checked: true },
                { name: '手续费扣除的金额', prop: 'commission', checked: true },
                { name: '实际到账金额', prop: 'actualAmount', checked: true },		
                { name: '积分类型', prop: 'integralType', checked: true },
                { name: '创建时间', prop: 'createTime', checked: true }
            ]
        }
    }

}