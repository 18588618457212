export const tableConfig=[
    {
        prop: "eventType",
        label: "变动事件类型",
        type:"state",
        dictType:'handle-style',
    },
    {
        prop: "preValue",
        label: "变动前资金数",
    },
    {
        prop: "changeValue",
        label: "变动数量",
    },
    {
        prop: "afterValue",
        label: "变动后资金数",
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
]

export const formConfig=[
  
]