export const tableConfig=[
    {
        prop: "directIntegralRate",
        label: "直推奖积分比例(%)",
    },
    {
        prop: "directIntegralType",
        label: "直推奖积分类型",
        type:"state",
        dictType:'integral-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }	
    
]

export const formConfig=[
    {
        prop: "directIntegralRate",
        label: "直推奖积分比例(%)",
        width:"80%",
        type:'number',
        explain:'单位百分比，如：5%请输入5'
    },
    {
        prop: "directIntegralType",
        label: "直推奖积分类型",
        type:"select",
        dictType:'integral-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]