export const tableConfig=[
    {
        prop: "day",
        label: "日期",
    },
    {
        prop: "poolValue",
        label: "资金数",
    },
    {
        prop: "createTime",
        label: "创建日期",
    }
]

export const formConfig=[
    {
        prop: "day",
        label: "日期",
        type:'datetime',
        dtype:'date'
    },
    {
        prop: "poolValue",
        label: "资金数",
        type:'number',
        width:'60%'
    }
]

export const editFormConfig=[
    {
        prop: "poolValue",
        label: "资金数",
        type:'number',
        width:'60%'
    }
]