import { showConfirm } from "@/common/showConfirm"
import showToast from "@/common/showToast"
import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.hasSearch = false

    //设置新建表单默认值
    // formDialogOption.dialogWidth = "30%"
    tableRequestOption.url = '/sys/paySetting/page'

    //删除参数
    tableDelDataOption.url = '/sys/paySetting/delete'
    tableDelDataOption.type = 'bodyArr'

    //禁用启用
    handleEventObj.start = (item)=>{
        showConfirm('确认启用该配置吗？',{
            confirm:async ()=>{
                await _axios('get',`/sys/paySetting/updateStatus?id=${item.id}&status=1`,{},true)
                customBtnEvent.refreshList()
            }
        })
    }
    //修改秘钥
    handleEventObj.changePwd = (item)=>{
        drawerOption.show = true
        drawerOption.size = '30%'
        drawerOption.title = "修改秘钥"
        drawerOption.item = item
        drawerOption.customCopName = 'changePayKey'
    }
}
