export const tableConfig=[
    {
        prop: "label",
        label: "视频描述",
    },
    {
        prop: "totalSeconds",
        label: "最少观看秒数",
        explain:"用户必须观看的最短时间，达到要求即可领取奖励"
    },
    {
        prop: "videoUrl",
        label: "视频",
        type:'video'
    },
    {
        prop: "sort",
        label: "排序",
    },
    
    // {
    //     prop: "status",
    //     label: "状态",
    //     type: "state",
    //     option:[{label:"禁用 ",value:0},{label:"启用",value:1}]
    // },  
    {
        prop: "createTime",
        label: "创建时间",
    }
]