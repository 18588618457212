export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'变动事件类型',prop:'eventType',type:'select',dictType:"handle-style"},
        {label:'创建时间',prop:'createTime',type:'mdate',start:'startTime',end:'endTime'},
    ]

    tableRequestOption.url = '/sys/poolRecord/page'

    //删除参数
    // tableDelDataOption.url = '/sys/giftPacksPoolSetting/delete'
    // tableDelDataOption.type = 'bodyArr'

    tableOption.exportOpt.fieldOption = [
        { name: '变动事件类型', prop: 'eventType', checked: true },
        { name: '变动人', prop: 'userName', checked: true },
        { name: '变动人账号', prop: 'account', checked: true },
        { name: '变动人手机号', prop: 'userMobile', checked: true },
        { name: '变动前资金数', prop: 'beforeValue', checked: true },
        { name: '变动数量', prop: 'changeValue', checked: true },
        { name: '变动后资金数', prop: 'afterValue', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]
}
