export const tableConfig=[
	{
		prop: "levelName",
		label: "获得分红最低等级",
	},
	{
		prop: "rewardIntegralType",
		label: "奖励积分类型",
		type: "select",
        dictType:'integral-style',
	},
	{
		prop: "directNum",
		label: "直推会员数",
	},
	{
        prop: "rewardIntegralPercent",
        label: "奖励积分百分比",
    },
	
   
    
]

export const formConfig=[
	{
		prop: "minLevelId",
		label: "获得分红最低等级",
		type:"select",

	},
    {
		prop: "rewardIntegralType",
		label: "奖励积分类型",
		type: "select",
        dictType:'integral-style',
	},
	{
        prop: "directNum",
        label: "直推会员数",
        type:"number",
    },
	{
        prop: "rewardIntegralPercent",
        label: "奖励积分百分比",
        type:"number",
		min:0,
		max:100,
        explain:'单位百分比(%),如：5%，输入5'
    },
	
]
