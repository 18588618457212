import { showConfirm } from "@/common/showConfirm"
import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    //搜索
    searchOption.hasSearch = false

    tableOption.topBtn = [
        {name:'绑定',uri:"bindData",icon:'{"name":"主要","type":"primary","plain":false}'}
    ]
    tableOption.rightBtn = [
        {name:'解绑',uri:"delData",icon:'{"name":"主要","type":"danger","plain":false}'},
    ]


    tableRequestOption.url = '/sys/integralVideo/pageAddedVideo'

    //设置新建表单默认值
    // formDialogOption.defaultItem = {commodityStatus:1} 
    // formDialogOption.dialogWidth = "40%"
    tableRequestOption.params = {integralVideoSettingId:route.query.id}

    //删除参数
    // tableDelDataOption.url = '/sys/integralVideoSetting/deleteVideo'
    // tableDelDataOption.type = 'bodyArr'
    handleEventObj.bindData = ()=>{
        drawerOption.show = true
        drawerOption.size = '70%'
        drawerOption.title = "筛选任务绑定视频"
        drawerOption.item = {}
        drawerOption.customCopName = 'bindTaskVideo'
    }
    handleEventObj.delData = (item)=>{
        const {id} = item
        showConfirm('是否解绑该视频？',{
            confirm:async ()=>{
                await _axios('post','/sys/integralVideoSetting/deleteVideo',[
                    {
                        integralVideoId:id,
                        integralVideoSettingId:route.query.id
                    }
                ],true)
                customBtnEvent.refreshList()
            },
            cancel:()=>{}
        })
    }
}
