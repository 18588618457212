export const tableConfig=[
    {
        prop: "levelName",
        label: "等级名称",
    },
    {
        prop: "type",
        label: "类型",
        type:"state",
        dictType:'handle-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }	
    
]

export const formConfig=[
    {
        prop: "levelId",
        label: "等级名称",
        type:"select",
        option:[]
    },
    {
        prop: "type",
        label: "类型",
        type:"select",
        dictType:'handle-style'
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]