export const tableConfig=[
	{
	    prop: "name",
	    label: "名称",
	},
	{
		prop:"rewardIntegralPercent",
		label:"奖励积分百分比(%)"
	},
	{
		prop: "createTime",
		label: "创建时间",
	},
    {
		prop: "rewardIntegralType",
		label: "奖励积分类型",
		type: "state",
        dictType:'integral-style',
	},
    
]

