export const tableConfig=[
    {
        prop: "day",
        label: "日期",
    },
    {
        prop: "cashPool",
        label: "资金池总数",
    },
    {
        prop: "zzhPrice",
        label: "ZZH价格",
    },
    {
        prop: "zzhPool",
        label: "ZZH总数量",
    },
    {
        prop: "calculateTime",
        label: "计算时间",
    },
]

export const formConfig=[
    {
        prop: "cashPool",
        label: "资金池总数",
        type:"number",
        width:'100%'
    },
    {
        prop: "zzhPrice",
        label: "ZZH价格",
        type:"number",
        width:'100%'
    },
    {
        prop: "zzhPool",
        label: "ZZH总数量",
        type:"number",
        width:'100%',
        exp:/^[1-9]\d*$/,
        tips:'ZZH总数量必须是大于0的正整数'
    },
]