export const tableConfig=[
    {
        prop: "minPrice",
        label: "大礼包最低消费金额",
    },
    {
        prop: "regionName",
        label: "专区名称",
    }
]

export const formConfig=[
    {
        prop: "minPrice",
        label: "大礼包最低消费金额",
        type:"number",
        width:"80%"
    },
    {
        prop: "regionId",
        label: "专区名称",
        type:"select",
        option:[]
    }
]