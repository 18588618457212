export const tableConfig = [{
    prop: "userName",
    label: "用户名称",
    fixed:true
},
{
    prop: "userMobile",
    label: "电话号码",
    fixed:true
},
{
    prop: "account",
    label: "账号",
},
{
    prop: "integralType",
    label: "积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "integralValue",
    label: "积分值",
},
{
    prop: "createTime",
    label: "创建时间",
}
]
