import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户姓名/账号/手机号码',prop:'account',type:'text'},
        {label:'积分类型',prop:'integralType',type:'select',dictType:"integral-style"},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    pageParams.pageSize=14
    tableRequestOption.url = '/sys/userIntegralVideoRecord/page'

    tableOption.exportOpt.fieldOption = [
        { name: '用户名称', prop: 'userName', checked: true },
        { name: '电话号码', prop: 'userMobile', checked: true },
        { name: '账号', prop: 'account', checked: true },
        { name: '视频任务名', prop: 'videoSettingLabel', checked: true },
        { name: '视频任务奖励积分类型', prop: 'rewardIntegralType', checked: true },
        { name: '视频任务奖励积分数', prop: 'rewardIntegralValue', checked: true },
        { name: '获得积分类型', prop: 'integralType', checked: true },
        { name: '获得积分值', prop: 'integralValue', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]
}