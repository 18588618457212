import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'发起人昵称/手机号码',prop:'fromNickName',type:'text'},
        {label:'接收人昵称/手机号码',prop:'toNickName',type:'text'},
        // {label:'积分类型',prop:'integralType',type:'select',dictType:"integral-style"},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    pageParams.pageSize=14
    tableRequestOption.url = '/sys/integralTransferRecord/page'

    tableOption.exportOpt.fieldOption = [
        { name: '发起用户昵称', prop: 'fromNickName', checked: true },
        { name: '发起用户手机号码', prop: 'fromMobile', checked: true },
        { name: '积分类型', prop: 'integralType', checked: true },
        { name: '积分值', prop: 'integralValue', checked: true },
        { name: '接收用户昵称', prop: 'toNickName', checked: true },
        { name: '接收用户手机号', prop: 'toMobile', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]
}