export const tableConfig=[
    {
        prop: "totalNum",
        label: "累计消费额",
    },
    {
        prop: "levelId",
        label: "任务等级",
        type:"state",
        option:[]
    },
    {
        prop: "regionId",
        label: "专区名称",
        type:"state",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status',
    }
]

export const formConfig=[
    {
        prop: "totalNum",
        label: "累计消费额",
        type:"number",
        width:"60%"
    },
    {
        prop: "levelId",
        label: "任务等级",
        type:"select",
        option:[]
    },
    {
        prop: "regionId",
        label: "专区名称",
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status',
    }
]