import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'姓名/电话',prop:'userRealName',type:'text'},
        // {label:'提现类型',prop:'type',type:'select',options:[
        //     {label:"微信收款码 ",value:1},{label:"支付宝账号",value:2},{label:"支付宝收款码",value:3},{label:"银行卡",value:4}
        // ]}, 
        {label:'提现状态',prop:'status',type:'select',dictType:"exam-status"},
        {label:'时间段筛选',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]

    tableRequestOption.url = '/sys/withdrawalRecord/page'

    //删除参数
    // tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/withdrawalRecord/delete'
    tableDelDataOption.valkey = 'withdrawalId'
    tableDelDataOption.type = 'bodyArr'
    
    //提现审核
    handleEventObj.check = (item)=>{
       formDialogOption.formTitle = '提现审核'
       formDialogOption.openType = 'custom'
       formDialogOption.item = item
       formDialogOption.showFormDialog = true
       formDialogOption.customCopName = 'withdrawalDetail'
    }

    //导出当前页
    handleEventObj.exportCurrent = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/withdrawalRecord/exportExcel',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/withdrawalRecord/exportExcel',
            fieldOption:[
                { name: '用户名称', prop: 'userRealName', checked: true },
                { name: '电话号码', prop: 'userMobile', checked: true },
                { name: '提现金额', prop: 'amount', checked: true },
                { name: '到账金额', prop: 'actualAmount', checked: true },
                { name: '打款状态', prop: 'payStatus', checked: true },
                { name: '打款时间', prop: 'payTime', checked: true },
                { name: '提现类型', prop: 'type', checked: true },
                { name: '审核状态', prop: 'status', checked: true },		
                { name: '错误码', prop: 'errCode', checked: true },
                { name: '错误码描述', prop: 'errCodeDesc', checked: true },
                { name: '返回码', prop: 'resutCode', checked: true },
                { name: '申请时间', prop: 'createTime', checked: true },
            ]
        }
    }
}
