export const tableConfig = [{
    prop: "fromNickName",
    label: "发起用户昵称",
    fixed:true
},
{
    prop: "fromMobile",
    label: "发起用户手机号码",
    fixed:true
},
{
    prop: "integralType",
    label: "积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "integralValue",
    label: "积分值",
},
{
    prop: "toNickName",
    label: "接收用户昵称",
},
{
    prop: "toMobile",
    label: "接收用户手机号",
},
{
    prop: "createTime",
    label: "创建时间",
}
]
