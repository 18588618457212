export const tableConfig=[
    {
        prop: "eventType",
        label: "变动事件类型",
        type:"state",
        dictType:'handle-style',
        fixed:true
    },
    {
        prop: "userName",
        label: "变动人",
        fixed:true
    },
    {
        prop: "account",
        label: "变动人账号",
    },
    {
        prop: "userMobile",
        label: "变动人手机号",
    },
    {
        prop: "beforeValue",
        label: "变动前资金数",
    },
    {
        prop: "changeValue",
        label: "变动数量",
    },
    {
        prop: "afterValue",
        label: "变动后资金数",
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
]

export const formConfig=[
  
]