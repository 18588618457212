export const tableConfig=[
	{
	    prop: "realName",
	    label: "真实姓名",
	},
	{
		prop: "userMobile",
		label: "手机号码",
	},
	{
		prop: "rewardBonusFlag",
		label: "是否开启加权分红",
		type:"state",
        option:[
            {label:"是",value:true},
            {label:"否",value:false}
        ] 
	},
	
    {
        prop: "account",
        label: "账号",
    },
	{
		prop: "wishIntegralValue",
		label: "如愿积分",
	},
	// {
	// 	prop: "healthCouponValue",
	// 	label: "健康购物券",
	// },
	{
		prop: "healthCouponValue",
		label: "换购积分",
	},
    // {
	// 	prop: "contributeValue",
	// 	label: "贡献值",
	// },
    // {
	// 	prop: "healthIntegralValue",
	// 	label: "福利积分",
	// },
    // {
	// 	prop: "shareValueValue",
	// 	label: "分红值",
	// },
    // {
	// 	prop: "consumerValue",
	// 	label: "消费积分",
	// },
    
	{
		prop: "userRegtime",
		label: "注册时间",
	},
	{
        prop: "levelName",
        label: "等级",
    }, 
    
]

export const formConfig=[
    {
		prop: "userMobile",
		label: "手机号码",
        type:'phone'
	},
	{
	    prop: "realName",
	    label: "真实姓名",
	},
	{
        prop: "account",
        label: "账号",
    },
	{
		prop: "rewardBonusFlag",
		label: "是否开启加权分红",
		type:"select",
        option:[
            {label:"是",value:true},
            {label:"否",value:false}
        ] 
	},
]

export const tableConfig2=[
	{
	    prop: "realName",
	    label: "真实姓名",
	},
	{
		prop: "userMobile",
		label: "手机号码",
	},
    {
        prop: "account",
        label: "账号",
    },
   
]