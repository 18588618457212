export const tableConfig=[
	{
		prop: "rewardIntegralType",
		label: "奖励积分类型",
		type: "state",
        dictType:'integral-style',
	},
	{
		prop: "rewardIntegralPercent",
		label: "奖励积分百分比(%)",
	},
	{
		prop: "createTime",
		label: "创建时间",
	},
   
    
]

export const formConfig=[
    {
		prop: "rewardIntegralType",
		label: "奖励积分类型",
		type: "select",
        dictType:'integral-style',
	},
	{
        prop: "rewardIntegralPercent",
        label: "奖励积分百分比(%)",
        type:"number",
        // explain:'单位百分比(%),如：5%，输入5'
    },
	{
		prop: "directNum",
		label: "直推人数",
		type:"number",
	},
]
