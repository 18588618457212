export const tableConfig=[
    {
        prop: "addNum",
        label: "购买创业礼包资金池增加比例(%)",
        width:500
    },
    {
        prop: "redPacksNum",
        label: "送完的红包数量",
        width:500
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "addNum",
        label: "购买创业礼包资金池增加比例(%)",
        type:'number',
        width:"60%"
    },
    {
        prop: "redPacksNum",
        label: "送完的红包数量",
        type:'number',
        width:"60%"
    },
    {
        prop: "regionId",
        label: "专区类型",
        disabled:true,
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]