export const tableConfig=[
    {
        prop: "rewardIntegralPercent",
        label: "直推奖积分比例(%)",
    },
    {
        prop: "rewardIntegralType",
        label: "直推奖积分类型",
        type:"state",
        dictType:'integral-style',
    },
    {
        prop: "type",
        label: "类型",
        type:"state",
        dictType:'handle-style',
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status',
    }
]

export const formConfig=[
    {
        prop: "rewardIntegralPercent",
        label: "直推奖积分比例(%)",
        type:"number",
        explain:'单位百分比(%),如：5%，输入5'
    },
    {
        prop: "rewardIntegralType",
        label: "直推奖积分类型",
        type:"select",
        dictType:'integral-style',
    },
    // {
    //     prop: "levelId",
    //     label: "任务等级",
    //     type:"select",
    //     option:[]
    // },
    {
        prop: "regionId",
        label: "专区类型",
        disabled:true,
        type:"select",
        option:[]
    },
    {
        prop: "type",
        label: "类型",
        type:"select",
        dictType:'handle-style',
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status',
    }
]