export const tableConfig=[
    {
        prop: "orderUserDto",
        subprop:'realName',
        label: "下单用户名称",
        fixed:true
    },
    {
        prop: "orderUserDto",
        subprop:'account',
        label: "下单用户账号",
    },
    {
        prop: "orderUserDto",
        subprop:'userMobile',
        label: "下单用户手机号",
    },
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "prodName",
        label: "产品名称",
    },
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "isPayed",
        label: "是否已经支付",
        type: "state",
        dictType:'pay-status',
    },
    {
        prop: "payType",
        label: "支付方式",
        type: "state",
        dictType:'pay-type',
    },
    {
        prop: "status",
        label: "订单状态",
        type: "state",
        dictType:'order-status',
    },
    {
        prop: "productNums",
        label: "订单商品总数",
    },
    {
        prop: "total",
        label: "订单金额",
    },
    {
        prop: "actualTotal",
        label: "订单实付金额",
        type: "number",
    },
    {
        prop: "reduceAmount",
        label: "优惠总额",
    },
    // {
    // 	prop: "orderType",
    // 	label: "订单类型",
    // 	type: "number",
    // },
    // {
    // 	prop: "addrOrderId",
    // 	label: "用户订单地址Id",
    // 	type: "number",
    // },
    // {
    // 	prop: "remarks",
    // 	label: "订单备注",
    // 	type: "",
    // },
    // {
    // 	prop: "ph",
    // 	label: "收货人手机号",
    // 	type: "",
    // },
    // {
    // 	prop: "dvyFlowId",
    // 	label: "物流单号",
    // 	type: "",
    // },
    // {
    // 	prop: "dvyTime",
    // 	label: "发货时间",
    // 	type: "",
    // },
    // {
    // 	prop: "dvyType",
    // 	label: "配送类型",
    // 	type: "",
    // },
    // {
    // 	prop: "finallyTime",
    // 	label: "	完成时间",
    // 	type: "",
    // },
    // {
    // 	prop: "freightAmount",
    // 	label: "	订单运费",
    // 	type: "",
    // 	width:120,
    // },
    // {
    // 	prop: "prodId",
    // 	label: "商品id",
    // 	type: "",
    // },
    {
        prop: "refundSts",
        label: "退款状态",
        type: "state",
        dictType:'refund-status',
    },
    // {
    // 	prop: "updateTime",
    // 	label: "订单更新时间",
    // 	type: "",
    // },
    // {
    // 	prop: "userId",
    // 	label: "订购用户ID",
    // 	type: "",
    // },
    // {
    // 	prop: "cancelTime",
    // 	label: "订单取消时间",
    // 	type: "text",
    // },
    {
        prop: "payTime",
        label: "付款时间",
    },
    {
        prop: "createTime",
        label: "下单时间",
    },
    {
        prop: "receiver",
        label: "收货人"
    },
    {
        prop: "mobile",
        label: "收货人电话"
    },
    {
        prop: "deliveryAddress",
        label: "收货地址"
    },
    {
    	prop: "remarks",
    	label: "订单备注",
    	type: "",
    },
    // {
    // 	prop: "closeType",
    // 	label: "订单关闭原因",
    // 	type: "",
    // },
]