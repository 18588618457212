export const tableConfig=[
    {
        prop: "account",
        label: "变动人账号",
        fixed:true
    },
    {
        prop: "userMobile",
        label: "变动人手机号",
        fixed:true
    },
    {
        prop: "realName",
        label: "变动人",
    },
    {
        prop: "transferFlag",
        label: "变动类型",
        type:"state",
        dictType:'transfer_flag',
    },
    {
        prop: "beforeValue",
        label: "变动前资金数",
    },
    {
        prop: "changeValue",
        label: "变动数量",
    },
    {
        prop: "afterValue",
        label: "变动后资金数",
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
]

export const formConfig=[
  
]