export const tableConfig = [
    // {
    //     prop: "userRealName",
    //     label: "用户名称",
    // },
   
    {
        prop: "account",
        label: "账号",
        fixed:true
    },
    {
        prop: "userRealName",
        label: "用户名称",
        fixed:true
    },
    {
        prop: "userMobile",
        label: "手机号码",
    },
    // {
    //     prop: "fromAccount",
    //     label: "转出方账号",
    // },
    // {
    //     prop: "fromUser",
    //     label: "转出方名称",
    // },
    // {
    //     prop: "inOut",
    //     label: "收支类型",
    //     type: "state",
    //     dictType:'income-style',
    // },
    {
        prop: "beforeIntegralValue",
        label: "原始积分数量",
    },
    {
        prop: "integralValue",
        label: "变动数量",
    },
    {
        prop: "afterIntegralValue",
        label: "剩余积分数量",
    },
    {
        prop: "integralType",
        label: "积分类型",
        type: "state",
        dictType:'integral-style',
    },
    {
        prop: "eventType",
        label: "操作事件",
        type: "state",
        dictType:'handle-style'
    },
   
   
    // {
    //     prop: "provideType",
    //     label: "实际消耗的积分类型",
    //     type: "state",
    //     option: [//1、贡献值；2、绿色积分；3、购物券；4、刺梨果
    //         {label: "贡献值",value: 1 },
    //         {label: "绿色积分",value: 2 },
    //         {label: "购物券",value: 3 },
    //         {label: "刺梨果",value: 4 },
    //          // {label:"现金",value:5},
    //          {label:"ZZH",value:6},
    //     ]
    // },
    // {
    //     prop: "provideNum",
    //     label: "实际消耗的积分数量",
    // },
    // {
    //     prop: "ratio",
    //     label: "比例(小数)",
    // },
    // {
    //     prop: "shouldNum",
    //     label: "应得数量",
    // },
    // {
    //     prop: "shouldProvideType",
    //     label: "应消耗的积分类型",
    //     type: "state",
    //     option: [
    //         {label: "贡献值",value: 1 },
    //         {label: "绿色积分",value: 2 },
    //         {label: "购物券",value: 3 },
    //         {label: "刺梨果",value: 4 },
    //         // {label:"现金",value:5},
    //         {label:"ZZH",value:6},
    //     ]
    // },
    // {
    //     prop: "shouldProvideNum",
    //     label: "应消耗的积分数量",
    // },
    // {
    //     prop: "remark",
    //     label: "备注",
    // },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
