export const tableConfig=[
    {
        prop: "categoryName",
        label: "分类名称",
        width:'300'
    },
    {
        prop: "icon",
        label: "分类图标",
        type:"img",
        width:'300'
    },
    {
        prop: "pic",
        label: "展示图片",
        type:"img",
        width:'300'
    },
    {
        prop: "status",
        label: "分类状态",
        type:"state",
        width:'300',
        dictType:'enable-status',
    },
]

export const formConfig=[
    {
        prop: "categoryName",
        label: "分类名称",
    },
    {
        prop: "icon",
        label: "分类图标",
        type:"img",
        tips:"尺寸：20x13，格式：jpg/png",
        required:false
    },
    {
        prop: "seq",
        label: "排序",
        type:'number',
        required:false
    },
    {
        prop: "status",
        label: "分类状态",
        type:"select",
        dictType:'enable-status',
    }
]