import showToast from '@/common/showToast';
import _axios from '@/plugins/axios';
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
    searchOption.searchType='conditionQuery'
    searchOptionsConditionQuery.options = [
		{label:'变动关联人',prop:'fromUserName',type:'text'},
        {label:'变动时间',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
		// {label:'商品分类',prop:'categoryId',type:'select',options:[],lkey:'categoryName',vkey:'categoryId'},
		// // {label:'租卖类型',prop:'type',type:'select',options:[
		// // 	{label:"只租",value:1},
		// // 	{label:"只卖",value:2},
		// // 	{label:"可租可卖",value:3},
		// // ]},
		// {label:'上架状态',prop:'commodityStatus',type:'select',options:[
		// 	{label:"下架",value:0},
		// 	{label:"上架",value:1}
		// ]}
	]
	

    //设置新建表单默认值
    tableRequestOption.url = '/sys/zzhPoolRecord/page'
    
}

