import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户姓名/账号/手机号码',prop:'account',type:'text'},
        // {label:'积分类型',prop:'integralType',type:'select',dictType:"integral-style"},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    pageParams.pageSize=14
    tableRequestOption.url = '/sys/userIntegralBurnRecord/page'

    tableOption.exportOpt.fieldOption = [
        { name: '变动人', prop: 'userName', checked: true },
        { name: '变动人账号', prop: 'account', checked: true },
        { name: '变动人手机号', prop: 'userMobile', checked: true },
        { name: '获得积分类型', prop: 'getIntegralType', checked: true },
        { name: '应得积分数', prop: 'getIntegralValueShould', checked: true },
        { name: '实得积分数', prop: 'getIntegralValueFact', checked: true },
        { name: '消耗积分类型', prop: 'provideIntegralType', checked: true },
        { name: '应消耗积分数', prop: 'provideIntegralValueShould', checked: true },
        { name: '实消耗积分数', prop: 'provideIntegralValueFact', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]
}