import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户姓名/账号/手机号码',prop:'userRealName',type:'text'},
        // {label:'手机号码',prop:'userMobile',type:'text'},
        // {label:'账号',prop:'account',type:'text'},
        {label:'收支类型',prop:'inOut',type:'select',dictType:"income-style"},
        {label:'积分类型',prop:'integralType',type:'select',dictType:"integral-style"},
        {label:'事件',prop:'eventType',type:'select',dictType:"handle-style"},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    pageParams.pageSize=14
    tableRequestOption.url = '/sys/userIntegralRecord/page'

     //导出当前页
     handleEventObj.exportCurrent = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/userIntegralRecord/exportExcel',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/userIntegralRecord/exportExcel',
            fieldOption:[
                { name: '电话号码', prop: 'userMobile', checked: true },
                { name: '账号', prop: 'account', checked: true },
                { name: '用户名称', prop: 'userRealName', checked: true },
                { name: '收支类型', prop: 'inOut', checked: true },
                { name: '积分类型', prop: 'integralType', checked: true },
                { name: '操作事件', prop: 'eventType', checked: true },
                { name: '数量', prop: 'integralValue', checked: true },		
                { name: '创建时间', prop: 'createTime', checked: true }
            ]
        }
    }

}