import { showConfirm } from "@/common/showConfirm"
import showToast from "@/common/showToast"
import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.hasSearch = false

    tableRequestOption.url = '/sys/pool/page'
    // formDialogOption.defaultItem = {status:1}

    //删除参数
    // tableDelDataOption.url = '/sys/giftPacksPoolSetting/delete'
    // tableDelDataOption.type = 'bodyArr'

    //手动触发分润
    handleEventObj.handleShare = (item)=>{
        showConfirm('确定开启分润吗？',{confirm:async ()=>{
            await _axios('get',`/sys/pool/doCalculateShare?pooId=${item.id}`,{},true)
            showToast.success('已分润！')
            customBtnEvent.refreshList('full')
        }})
    }


    tableOption.exportOpt.fieldOption = [
        { name: '日期/名称', prop: 'day', checked: true },
        { name: '资金数', prop: 'poolValue', checked: true },
        { name: '分红状态', prop: 'status', checked: true },
        { name: '分红方式', prop: 'triggerFlag', checked: true },
        { name: '创建日期', prop: 'createTime', checked: true },
    ]
}
