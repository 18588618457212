export const tableConfig = [
    {
        prop: "name",
        label: "等级名称",
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
    {
        prop: "directPercent",
        label: "直推奖百分比（%）",
    },
    {
        prop: "indirectPercent",
        label: "业绩奖百分比（%）",
    },
    {
        prop: "upType",
        label: "升级类型",
        type: "state",
        option: [
            { label: "消费额", value: 1 },
            { label: "前置等级", value: 2 },
            { label: "小区业绩", value: 3 },
        ],
    },
];

export const formConfig = [
    {
        prop: "userMobile",
        label: "手机号码",
        type: "phone",
    },
];

export const tableConfig2 = [
    {
        prop: "realName",
        label: "真实姓名",
    },
    {
        prop: "userMobile",
        label: "手机号码",
    },
    {
        prop: "account",
        label: "账号",
    },
    {
        prop: "status",
        label: "状态",
        type: "state",
        dictType: "enable-status",
    },
];
