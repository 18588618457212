export const tableConfig=[
    {
        prop: "minValue",
        label: "提现最小值",
    },
    {
        prop: "commissions",
        label: "手续费比例",
    },
    {
        prop: "integralType",
        label: "类型",
        type:"state",
        width:'200',
        dictType:'integral-style'
    },
]

export const formConfig=[
    {
        prop: "minValue",
        label: "提现最小值",
        type:"number"
    },
    {
        prop: "commissions",
        label: "手续费比例(小数)",
        // exp:/^[1-9]\d*$/,
        explain:'比如：提现/兑换手续费5%，则输入0.05',
        type:"number"
    },
    {
        prop: "integralType",
        label: "类型",
        type:"select",
        dictType:'integral-style'
    },
]