export const tableConfig=[
    {
        prop: "realName",
        label: "真实姓名",
    },
    {
        prop: "idCard",
        label: "身份证号",
    },
    {
        prop: "frontImages",
        label: "身份证正面",
        type:"img"
    },
    {
        prop: "backImages",
        label: "身份证反面",
        type:"img"
    }
]
