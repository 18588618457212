export const tableConfig = [
    {
        prop: "userName",
        label: "用户名称",
        fixed:true
    },
    {
        prop: "userMobile",
        label: "手机号码",
    },
    {
        prop: "account",
        label: "账号",
    },
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "day",
        label: "期数",
    },
    {
        prop: "changeValue",
        label: "变动数量",
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
