export const tableConfig=[
    {
        prop: "label",
        label: "任务名称",
    },
    {
        prop: "defaultFlag",
        label: "是否默认",
        type: "state",
        dictType:'default-status'
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type: "state",
        dictType:'integral-style'
    },
    {
        prop: "rewardIntegralValue",
        label: "奖励积分数量",
    },
    {
        prop: "status",
        label: "状态",
        type: "state",
        dictType:'enable-status'
    },  
    {
        prop: "createTime",
        label: "创建时间",
    }
]

export const formConfig=[
    {
        prop: "label",
        label: "任务名称",
    },
    {
        prop: "defaultFlag",
        label: "是否默认",
        type: "select",
        dictType:'default-status'
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type: "select",
        dictType:'integral-style'
    },
    {
        prop: "rewardIntegralValue",
        label: "奖励积分数量",
        type:"number",
        width:'60%'
    },
    {
        prop: "status",
        label: "状态",
        type: "select",
        dictType:'enable-status'
    }, 
]