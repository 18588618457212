export const tableConfig=[
    {
        prop: "name",
        label: "资金池名称",
    },
    {
        prop: "amount",
        label: "池内资金",
    },
    {
        prop: "note",
        label: "备注",
    },
    {
        prop: "createTime",
        label: "创建日期",
    }
]

export const formConfig=[
    {
        prop: "name",
        label: "资金池名称",
    },
    {
        prop: "note",
        label: "备注",
    },
]