export const tableConfig = [
    {
        prop: "userName",
        label: "变动人",
        fixed:true
    },
    {
        prop: "account",
        label: "变动人账号",
    },
    {
        prop: "poolValue",
        label: "资金池变动数量",
    },
    {
        prop: "addUpdateFlag",
        label: "变动类型",
        type:'state',
        dictType:'fund-pool-style'
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
