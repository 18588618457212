import exportExcel from "@/common/exportExcel";
export default function (
  store,
  route,
  router,
  tableColumnConfig, //表格表头
  formDialogOption, //表单弹窗相关配置
  searchParams, //搜索条件
  searchOption, //顶部搜索配置   searchType为fuzzyQuery conditionQuery
  searchOptionsFuzzyQuery, //模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  searchOptionsConditionQuery, //条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  pageParams, //页面相关配置
  tableRequestOption, //表格获取数据相关配置
  tableOption, //表格属性配置
  tableDelDataOption, //数据删除相关配置
  handleEventObj, //顶部及右侧操作按钮事件
  customBtnEvent, //自定义事件
  drawerOption
) {
  // searchOption.searchType = 'conditionQuery'
  // searchOptionsConditionQuery.options = [
  //     // {label:'用户昵称',prop:'nickName',type:'text'},
  //     {label:'手机号码',prop:'userMobile',type:'text'},
  // ]
  //搜索
  searchOptionsFuzzyQuery.val = {
    keys: "name",
    placeholder: "等级名称",
  };

  tableRequestOption.url = "/sys/userLevel/page";

  tableDelDataOption.url = "/sys/userLevel/delete";
  tableDelDataOption.type = "bodyArr";
  
  handleEventObj.newData = () => {
    formDialogOption.item = null;
    formDialogOption.dialogWidth = "600px";
    formDialogOption.formTitle = "用户等级--新建";
    formDialogOption.openType = "custom";
    formDialogOption.showFormDialog = true;
    formDialogOption.customCopName = "userLevelAddOrEdit";
  };
  handleEventObj.editData = (item) => {
    formDialogOption.item = item;
    formDialogOption.dialogWidth = "600px";
    formDialogOption.formTitle = "用户等级--编辑";
    formDialogOption.openType = "custom";
    formDialogOption.showFormDialog = true;
    formDialogOption.customCopName = "userLevelAddOrEdit";
  };
}
