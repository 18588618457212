export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    // searchOption.hasSearch = false
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        {label:'申请类型',prop:'applyType',type:'select',dictType:'after-sales-style'},
        {label:'退款状态',prop:'returnMoneySts',type:'select',options:[
            {label:"退款处理中",value:0},
            {label:"退款成功",value:1},
            {label:"退款失败",value:-1},
        ]},
        {label:'申请时间',prop:'applyTime',type:'datetime',start:'startTime',end:'endTime'},
        {label:'退款时间',prop:'refundTime',type:'datetime',start:'startTime',end:'endTime'},
       
    ]
 
    //设置新建表单默认值
    formDialogOption.dialogWidth = "50%"
    tableRequestOption.url = '/sys/orderRefund/Page'

    //删除参数
    // tableDelDataOption.url = '/sys/news/delete'
    // tableDelDataOption.type = 'bodyArr'

    handleEventObj.checkRefund = (item)=>{
        formDialogOption.formTitle = '售后-退款/退货'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
        formDialogOption.dialogWidth = '1500px'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'refundCheck'
    }


    tableOption.exportOpt.fieldOption = [
        { name: '申请记录号', prop: 'refundId', checked: true },
        { name: '申请时间', prop: 'applyTime', checked: true },
        { name: '退款状态', prop: 'refundSts', checked: true },
        { name: '申请类型', prop: 'applyType', checked: true },
        { name: '申请原因', prop: 'buyerMsg', checked: true },
        { name: '退货数量', prop: 'goodsNum', checked: true },
        { name: '退款金额', prop: 'refundAmount', checked: true },
        { name: '退款编号', prop: 'refundSn', checked: true },
        { name: '退款时间', prop: 'refundTime', checked: true },
        { name: '备注', prop: 'sellerMsg', checked: true },
        { name: '处理退款状态', prop: 'returnMoneySts', checked: true },
        { name: '订单流水号', prop: 'orderNumber', checked: true },
        { name: '订单支付流水号', prop: 'flowTradeNo', checked: true },
        { name: '订单总金额', prop: 'orderAmount', checked: true },
        { name: '订单支付名称', prop: 'payTypeName', checked: true },
        { name: '订单支付方式', prop: 'payType', checked: true },
        { name: '物流公司名称', prop: 'expressName', checked: true },
        { name: '物流单号', prop: 'expressNo', checked: true },
        { name: '收货备注', prop: 'receiveMessage', checked: true },
        { name: '收货时间', prop: 'receiveTime', checked: true },
    ]
}
