export const tableConfig = [{
    prop: "userName",
    label: "用户名称",
    fixed:true
},
{
    prop: "userMobile",
    label: "电话号码",
    fixed:true
},
{
    prop: "account",
    label: "账号",
},
{
    prop: "videoSettingLabel",
    label: "视频任务名",
},
{
    prop: "rewardIntegralType",
    label: "视频任务奖励积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "rewardIntegralValue",
    label: "视频任务奖励积分数",
},
{
    prop: "integralType",
    label: "获得积分类型",
    type:'state',
    dictType:'integral-style'
},
{
    prop: "integralValue",
    label: "获得积分值",
},
{
    prop: "createTime",
    label: "创建时间",
}
]
