import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户姓名/账号/手机号码',prop:'userName',type:'text'},
        {label:'专区名称',prop:'regionId',type:'select',options:[],lkey:'regionName',vkey:'id'},
        {label:'积分类型',prop:'integralType',type:'select',dictType:"integral-style"},
        {label:'创建日期',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]
 
    // pageParams.pageSize=14
    tableRequestOption.url = '/sys/rewardStaticRecord/page'

    tableOption.exportOpt.fieldOption = [
        { name: '用户名称', prop: 'userName', checked: true },
        { name: '电话号码', prop: 'userMobile', checked: true },
        { name: '账号', prop: 'account', checked: true },
        { name: '专区名称', prop: 'regionName', checked: true },
        { name: '订单号', prop: 'orderNumber', checked: true },
        { name: '积分类型', prop: 'integralType', checked: true },
        { name: '积分值', prop: 'integralValue', checked: true },
        { name: '奖励百分比(%)', prop: 'rewardPercent', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]

    getAreaList()
    async function getAreaList(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        searchOptionsConditionQuery.options[1].options = res
    }
}