export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.hasSearch = false

    //设置新建表单默认值
    formDialogOption.defaultItem = {status:1,type:2}
    // formDialogOption.dialogWidth = "30%"
    tableRequestOption.params = {type:2}
    tableRequestOption.url = '/sys/userLevel/page'

    //删除参数
    // tableDelDataOption.url = '/sys/userLevel/delete'
    // tableDelDataOption.type = 'bodyArr'

    handleEventObj.setRule = (item)=>{
        if(item.code==0) return showToast.warning("0级会员没有升级规则！")
        drawerOption.show = true
        drawerOption.size = '60%'
        drawerOption.title = "会员升级规则配置"
        drawerOption.item = item
        drawerOption.customCopName = 'upgradeRules'
    }
}
