import exportExcel from "@/common/exportExcel";
export default function (
  store,
  route,
  router,
  tableColumnConfig, //表格表头
  formDialogOption, //表单弹窗相关配置
  searchParams, //搜索条件
  searchOption, //顶部搜索配置   searchType为fuzzyQuery conditionQuery
  searchOptionsFuzzyQuery, //模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  searchOptionsConditionQuery, //条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  pageParams, //页面相关配置
  tableRequestOption, //表格获取数据相关配置
  tableOption, //表格属性配置
  tableDelDataOption, //数据删除相关配置
  handleEventObj, //顶部及右侧操作按钮事件
  customBtnEvent, //自定义事件
  drawerOption
) {
  // searchOption.searchType = 'conditionQuery'
  // searchOptionsConditionQuery.options = [
  //     // {label:'用户昵称',prop:'nickName',type:'text'},
  //     {label:'手机号码',prop:'userMobile',type:'text'},
  // ]
  //搜索
  searchOptionsFuzzyQuery.val = {
    keys: "name",
    placeholder: "等级名称",
  };
  formDialogOption.dialogWidth = "500px"
  formDialogOption.labelWidth = 240

  tableRequestOption.url = "/sys/rewardPerformance/page";

  tableDelDataOption.url = "/sys/rewardPerformance/delete";
  tableDelDataOption.type = "bodyArr";

}
