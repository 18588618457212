export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户姓名/账号/手机号码',prop:'realName',type:'text'},
        {label:'领取状态',prop:'status',type:'select',dictType:"shopping-voucher-record-status"},
        {label:'发放时间',prop:'distributionTime',type:'mdate',start:'startTime',end:'endTime'},
    ]

    tableRequestOption.url = '/sys/shoppingVoucherRecord/page'

    //删除参数
    // tableDelDataOption.url = '/sys/giftPacksPoolSetting/delete'
    // tableDelDataOption.type = 'bodyArr'

    tableOption.exportOpt.fieldOption = [
        { name: '分红人', prop: 'realName', checked: true },
        { name: '分红人账号', prop: 'account', checked: true },
        { name: '分红人手机号', prop: 'userMobile', checked: true },
        { name: '分红基数', prop: 'oriValue', checked: true },
        { name: '实际到账数额', prop: 'actualValue', checked: true },
        { name: '发放时间', prop: 'distributionTime', checked: true },
        { name: '领取状态', prop: 'status', checked: true },
        { name: '创建时间', prop: 'createTime', checked: true },
    ]
}
