export const tableConfig=[
    {
        prop: "dvyName",
        label: "物流公司名称",
    },
    {
        prop: "pic",
        label: "logo",
        type:'img'
    },
    {
        prop: "code",
        label: "快递缩写",
    },
    {
        prop: "companyHomeUrl",
        label: "公司主页",
    }
]

export const formConfig=[
    {
        prop: "dvyName",
        label: "物流公司名称",
    },
    {
        prop: "pic",
        label: "logo",
        type:'img'
    },
    {
        prop: "code",
        label: "快递缩写",
        required:false
    },
    {
        prop: "companyHomeUrl",
        label: "公司主页",
        required:false
    }
]