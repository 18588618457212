export const tableConfig=[
    {
        prop: "rewardIntegralPercent",
        label: "奖励积分比例(%)",
        width:500
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:'state',
        dictType:'integral-style',
        width:500
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "rewardIntegralPercent",
        label: "奖励积分比例(%)",
        type:'number',
        explain:'单位百分比，如：5%请输入5'
    },
    {
        prop: "rewardIntegralType",
        label: "奖励积分类型",
        type:'select',
        dictType:'integral-style',
    },
    {
        prop: "regionId",
        label: "专区名称",
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]