import showToast from '@/common/showToast';
import _axios from '@/plugins/axios';
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    tableOption.topBtn = 1 //1表示空
        // {name:'新建',uri:"newData",icon:'{"name":"主要","type":"success","plain":false}'}
    tableOption.rightBtn = 1
        // {name:'编辑',uri:"editData",icon:'{"name":"主要","type":"success","plain":true}'},
        // {name:'删除',uri:"delData",icon:'{"name":"主要","type":"danger","plain":false}'},

    //搜索
    searchOption.searchType='conditionQuery'
    searchOptionsConditionQuery.options = [
		{label:'商品名称',prop:'commodityName',type:'text'},
		{label:'商品分类',prop:'categoryId',type:'select',options:[],lkey:'categoryName',vkey:'categoryId'},
		// {label:'租卖类型',prop:'type',type:'select',options:[
		// 	{label:"只租",value:1},
		// 	{label:"只卖",value:2},
		// 	{label:"可租可卖",value:3},
		// ]},
		{label:'上架状态',prop:'commodityStatus',type:'select',options:[
			{label:"下架",value:0},
			{label:"上架",value:1}
		]}
	]
	searchOptionsConditionQuery.options.map(async(v)=>{
		if(v.prop=='categoryId'){
			v.options = await getCategory()
		}
	})

    //设置新建表单默认值
    formDialogOption.defaultItem = {commodityStatus:1} 
    formDialogOption.dialogWidth = "40%"

    tableRequestOption.url = '/sys/commodity/pageRegion'
    tableRequestOption.params = {regionId:route.query.id}
    
    //商品详情
    // handleEventObj.viewDetail = (item)=>{
    //     router.push({path:'/goods-detail',query:{productId:item.commodityId}})
    // }
    //商品编辑
    handleEventObj.editData = (item)=>{
        router.push({path:'/sa-goods-edit',query:{
            id:route.query.id,
            commodityId:item.commodityId,
            type:route.query.type,
            title:route.query.title
        }})
    }
    handleEventObj.newData = (item)=>{
        router.push({path:'/sa-goods-add',query:{
            id:route.query.id,
            type:route.query.type,
            title:route.query.title
        }})
    }
    handleEventObj.delData = async (item)=>{
        await _axios('post','/sys/productRegion/deleteCommodity',[
            {
                commodityId:item.commodityId,
                regionId:route.query.id
            }
        ],true)
        showToast.success("商品已删除")
        customBtnEvent.refreshList()
    }
}

//获取商品分类
async function getCategory(){
    const res = await _axios('get','/sys/category/listCategory',{},true)
    return res
}
