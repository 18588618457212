import { showConfirm } from "@/common/showConfirm"
import showToast from "@/common/showToast"
import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'姓名/电话',prop:'userRealName',type:'text'},
        {label:'兑换类型',prop:'type',type:'select',options:[
            {label:"绿色积分兑换刺梨果",value:2},
            {label:"ZZH兑换刺梨果",value:6},
        ]},
        {label:'审批状态',prop:'status',type:'select',options:[
            {label:"待审批",value:1},//1 待审批 2 通过 3拒绝
            {label:"通过",value:2},
            {label:"拒绝",value:3},
        ]},
    ]

    tableRequestOption.url = '/sys/integralExchangeRecord/page'

    //删除参数
    tableDelDataOption.url = '/sys/integralExchangeRecord/delete'
    tableDelDataOption.type = 'bodyArr'

    //兑换审核
    handleEventObj.check = (item)=>{
        if(item.status!=1) return showToast.warning('已审核，请勿重复操作！')
        showConfirm('兑换审核',{option:{
            confirmTitle:'同意',
            closeTitle:'拒绝',
        },confirm:async ()=>{
            await _axios('post','/sys/integralExchangeRecord/doCheck',{id:item.id,status:2},true)
            customBtnEvent.refreshList()
        },cancel:async ()=>{
            await _axios('post','/sys/integralExchangeRecord/doCheck',{id:item.id,status:3},true)
            customBtnEvent.refreshList()
        }})
    }
}
