export const tableConfig=[
    {
        prop: "title",
        label: "标题",
        width:300
    },
    {
        prop: "pic",
        label: "展示图片",
        type:"img",
        align:"center",
        width:300
    },
    {
        prop: "videoUrl",
        label: "展示视频",
        type:"video",
        align:"center",
        width:300
    },
    {
        prop: "describe",
        label: "简介描述",
        width:300
    },
    {
        prop: "createTime",
        label: "创建时间",
        width:300
    },
]

export const formConfig=[
    {
        prop: "title",
        label: "标题",
    },
    {
        prop: "describe",
        label: "简介描述",
        type:'textarea'
    },
    {
        prop: "pic",
        label: "封面图片",
        type:"img",
        tips:"推荐尺寸：323*156"
    },
    {
        prop: "videoUrl",
        label: "展示视频",
        type:"video",
        required:false,
        limit:1
    },
    {
        prop: "note",
        label: "详情",
        type:'editor'
    },
]

