export const tableConfig = [
    {
        prop: "userName",
        label: "变动人",
        fixed:true
    },
    {
        prop: "account",
        label: "变动人账号",
    },
    {
        prop: "userMobile",
        label: "变动人手机号",
    },
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "poolValue",
        label: "资金池变动数量",
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
