import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj
){
   
    formOption.updateApiOption.api = `/sys/user/update`
    formOption.labelWidth = 160
    
    // getUserVipLevel()
    // async function getUserVipLevel(){
    //     const res =await _axios('post','/sys/userLevel/list',{memberType:1},true)
    //     formColumnConfig.map(v=>{
    //         if(v.prop==='userLevelId'){
    //             v.lkey = 'levelName'
    //             v.vkey = 'levelId'
    //             v.option = res
    //         }
    //     })
    // }
}
