export const tableConfig=[
    {
        prop: "refundId",
        label: "申请记录号",
    },
    {
        prop: "orderNumber",
        label: "订单流水号",
    },
    {
        prop: "applyTime",
        label: "申请时间",
    },
    {
        prop: "applyType",
        label: "申请类型",
        type: "state",
        dictType:'after-sales-style',
    },
    {
        prop: "buyerMsg",
        label: "申请原因",
    },
    {
        prop: "refundSts",
        label: "审核状态",
        type: "state",
        dictType:'refund-exam-status',
    },
    {
        prop: "sellerMsg",
        label: "审核备注",
    },
    {
        prop: "goodsNum",
        label: "退货数量",
    },
    {
        prop: "refundAmount",
        label: "退款金额",
    },
    {
        prop: "refundSn",
        label: "换货、退款/退货编号",
    },
    {
        prop: "refundTime",
        label: "换货、退款/退货时间",
    },
    {
        prop: "returnMoneySts",
        label: "处理退款状态",
        type: "state",
        dictType:'refund-handle-status',
    },
    
    {
        prop: "flowTradeNo",
        label: "订单支付流水号",
    },
    {
        prop: "orderAmount",
        label: "订单总金额",
    },
    {
        prop: "payTypeName",
        label: "订单支付名称",
    },
    {
        prop: "payType",
        label: "订单支付方式",
        type: "state",
        dictType:'pay-type',
    },
   
    // {
    // 	prop: "outRefundNo",
    // 	label: "第三方退款单号",
    // 	type: "text",
    // },
    {
        prop: "expressName",
        label: "物流公司名称",
    },
    {
        prop: "expressNo",
        label: "物流单号",
    },
    // {
    // 	prop: "handelTime",
    // 	label: "卖家处理时间",
    // 	type: "text",
    // },
    
    // {
    // 	prop: "orderId",
    // 	label: "订单ID",
    // 	type: "text",
    // },
    // {
    // 	prop: "orderItemId",
    // 	label: "订单项ID",
    // 	type: "text",
    // },
    
    
    {
        prop: "receiveMessage",
        label: "收货备注",
    },
    {
        prop: "receiveTime",
        label: "收货时间",
    }
    // {
    // 	prop: "sellerMsg",
    // 	label: "卖家备注",
    // 	type: "text",
    // }
]