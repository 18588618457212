export const tableConfig=[
    {
        prop: "directNum",
        label: "一个红包直推奖励积分数",
        width:500
    },
    {
        prop: "indirectNum",
        label: "一个红包间推奖励积分数",
        width:500
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "directNum",
        label: "一个红包直推奖励积分数",
        type:'number',
        width:"60%"
    },
    {
        prop: "indirectNum",
        label: "一个红包间推奖励积分数",
        type:'number',
        width:"60%"
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]