export const tableConfig=[
    {
        prop: "fromUserName",
        label: "变动关联人名称",
    },
    {
        prop: "updateTime",
        label: "变动时间",
    },
    {
        prop: "prePoolAmount",
        label: "变动之前资金池数量",
    },
    {
        prop: "changePoolAmount",
        label: "变动的资金池数量",
    },
    {
        prop: "afterPoolAmount",
        label: "变动之后资金池数量",
    },
    {
        prop: "preZzhAmount",
        label: "变动之前ZZH总数",
    },
    {
        prop: "changeZzhAmount",
        label: "变动的ZZH数量",
    },
    {
        prop: "afterZzhAmount",
        label: "变动之后ZZH数量",
    },
    
]

export const formConfig=[
    
]