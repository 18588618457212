import showToast from '@/common/showToast';
import _axios from '@/plugins/axios';
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
    searchOption.searchType='conditionQuery'
    searchOptionsConditionQuery.options = [
		{label:'日期',prop:'mdate',type:'mdate',start:'startDate',end:'endDate'},
	]
	

    //设置新建表单默认值
    tableRequestOption.url = '/sys/zzhPool/page'
    
    //补发ZZH
    handleEventObj.supplyAgain = async ()=>{
        await _axios('get','/sys/zzhPool/initializationPool',{},true)
        customBtnEvent.refreshList()
    }

    //主动触发计算
    handleEventObj.activeTrigger = async ()=>{
        setParams('activeTrigger')
    }
    //初始化用户账户
    handleEventObj.initUserAccount = async ()=>{
        setParams('initUserAccount')
    }
    //23补发后
    handleEventObj.supplyAgainAfter = async ()=>{
        setParams('supplyAgainAfter')
    }
    //23补发钱
    handleEventObj.supplyAgainBefore = async ()=>{
        setParams('supplyAgainBefore')
    }

    function setParams(type){
        formDialogOption.formTitle = '设置参数'
        formDialogOption.openType = 'custom'
        formDialogOption.item = {type:type}
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'setParams'
    }
}

