import exportExcel from "@/common/exportExcel"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    // searchOption.searchType = 'conditionQuery'
	// searchOptionsConditionQuery.options = [
    //     // {label:'用户昵称',prop:'nickName',type:'text'},
    //     {label:'手机号码',prop:'userMobile',type:'text'},
    // ]
    
    searchOption.hasSearchCustomTpl = true
    searchOption.searchCustomTplName = 'selectUserGroupList'
     //搜索
	searchOptionsFuzzyQuery.val = {
	    keys:'userMobile',
	    placeholder:'姓名/手机号码/账号'
	}

    tableRequestOption.url = '/sys/user/page'

    // handleEventObj.resetFormDialogWidth=()=>{
    //     formDialogOption.dialogWidth = "30%"
    // }
    handleEventObj.levelChange = (item)=>{
        formDialogOption.formTitle = '用户--等级修改'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
         formDialogOption.dialogWidth = '600px'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'levelChange'
    }
    //重置密码
    handleEventObj.resetPwd = (item)=>{
        formDialogOption.formTitle = '用户--重置密码'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'resetUserPwd'
    }
    //用户等级
    handleEventObj.userLevel = (item)=>{
        drawerOption.show = true
        drawerOption.title = "等级信息"
        drawerOption.item = item
        drawerOption.customCopName = 'userLevel'
    }
    //修改积分
    handleEventObj.changeScore = (item)=>{
        drawerOption.show = true
        drawerOption.title = "积分修改"
        drawerOption.item = item
        drawerOption.customCopName = 'userScoreChange'
    }
    //增补积分
    handleEventObj.replenishScore =()=>{
        drawerOption.show = true
        drawerOption.title = "用户补积分"
        drawerOption.customCopName = 'userReplenishScore'
    }
    //导出补分模板
    handleEventObj.exportUserTpl = ()=>{
        exportExcel('/sys/userIntegral/exportDataTemplate',{},{method:'GET'})
    }

     //导出当前页
     handleEventObj.exportCurrent = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/user/exportExcel',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/user/exportExcel',
            fieldOption:[
                { name: '真实姓名', prop: 'realName', checked: true },
                { name: '手机号码', prop: 'userMobile', checked: true },
                { name: '账号', prop: 'account', checked: true },
                { name: '如愿积分', prop: 'wishIntegralValue', checked: true },
                { name: '健康购物券', prop: 'healthCouponValue', checked: true },
                { name: '贡献值', prop: 'contributeValue', checked: true },		
                { name: '福利积分', prop: 'healthIntegralValue', checked: true },
                { name: '分红值', prop: 'shareValueValue', checked: true },
                { name: '状态', prop: 'status', checked: true },
                { name: '注册时间', prop: 'userRegtime', checked: true }
            ]
        }
    }

}
