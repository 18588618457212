export const tableConfig=[
    {
        prop: "integralType",
        label: "积分类型",
        type:"state",
        dictType:'integral-style',
    },
    {
        prop: "integralValue",
        label: "积分值",
    },
    {
        prop: "levelName",
        label: "等级名称",
    },
    {
        prop: "levelNum",
        label: "分享个数",
    },
    {
        prop: "giftNum",
        label: "送完礼包数",
    },
]

export const formConfig=[
    {
        prop: "integralType",
        label: "积分类型",
        type:"select",
        dictType:'integral-style',
    },
    {
        prop: "integralValue",
        label: "积分值",
        type:"number"
    },
    {
        prop: "levelId",
        label: "等级",
        type:"select",
        option:[]
    },
    {
        prop: "levelNum",
        label: "分享个数",
        type:"number"
    },
    {
        prop: "giftNum",
        label: "送完礼包数",
        type:"number"
    },
]