export const tableConfig=[
    {
        prop: "labelName",
        label: "标签名称",
        width:'300',
    },
    {
        prop: "labelCode",
        label: "标签Code码",
        width:'300'
    },
    {
        prop: "categoryName",
        label: "产品分类",
        width:'300'
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        width:'200',
        dictType:'enable-status',
    },
    {
        prop: "createTime",
        width:'250',
        label: "创建时间"
    }
]

export const formConfig=[
    {
        prop: "labelName",
        label: "标签名称",
    },
    {
        prop: "labelCode",
        label: "标签Code码",
    },
    {
        prop: "categoryId",
        label: "产品分类",
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:"select",
        dictType:'enable-status',
    }
]