export const tableConfig=[
    {
        prop:'merchantNo',
        label:'商户号'
    },
    {
        prop:'wxAppId',
        label:'小程序appid'
    },
    {
        prop:'key',
        label:'md5密匙'
    },
    {
        prop:'publicRsa',
        label:'rsa公钥'
    },
    {
        prop:'priviteRsa',
        label:'rsa私钥'
    },
    {
        prop:'tradeMerchantNo',
        label:'报备商户号'
    },
    {
        prop: "type",
        label: "支付方式",
        type: "state",
        option: [
            {label: "汇聚支付",value: 1},
            {label: "微信直连",value: 2},
            {label: "支付宝直连",value: 3},
            // 1 汇聚支付 2 微信直连 3 支付宝直连 默认 汇聚支付
        ]
    },
    // {
    //     prop: "platformType",
    //     label: "平台类型",
    //     type: "state",
    //     option: [
    //         {label: "APP",value: 1},
    //         {label: "H5",value: 2},
    //         // 1 汇聚支付 2 微信直连 3 支付宝直连 默认 汇聚支付
    //     ]
    // },
    {
        prop: "status",
        label: "状态",
        type: "state",
        dictType:'pay-status',
    }
]

export const formConfig=[
    {
        prop:'merchantNo',
        label:'商户号'
    },
    {
        prop:'wxAppId',
        label:'小程序appid'
    },
    {
        prop:'key',
        label:'md5密匙'
    },
    {
        prop:'publicRsa',
        label:'rsa公钥'
    },
    {
        prop:'priviteRsa',
        label:'rsa私钥'
    },
    {
        prop:'tradeMerchantNo',
        label:'报备商户号'
    },
    {
        prop: "type",
        label: "支付方式",
        type: "select",
        option: [
            {label: "汇聚支付",value: 1},
            {label: "微信直连",value: 2},
            {label: "支付宝直连",value: 3},
            // 1 汇聚支付 2 微信直连 3 支付宝直连 默认 汇聚支付
        ]
    },
    // {
    //     prop: "platformType",
    //     label: "平台类型",
    //     type: "select",
    //     option: [
    //         {label: "APP",value: 1},
    //         {label: "H5",value: 2},
    //         // 1 汇聚支付 2 微信直连 3 支付宝直连 默认 汇聚支付
    //     ]
    // },
    // {
    //     prop: "staus",
    //     label: "状态",
    //     type: "select",
    //     option: [
    //         {label: "禁用",value: 0},
    //         {label: "启用",value: 1},
    //     ]
    // }
]
export const editFormConfig=[
    {
        prop:'merchantNo',
        label:'商户号'
    },
    {
        prop:'wxAppId',
        label:'小程序appid'
    },
    {
        prop:'tradeMerchantNo',
        label:'报备商户号'
    },
    {
        prop: "type",
        label: "支付方式",
        type: "select",
        option: [
            {label: "汇聚支付",value: 1},
            {label: "微信直连",value: 2},
            {label: "支付宝直连",value: 3},
            // 1 汇聚支付 2 微信直连 3 支付宝直连 默认 汇聚支付
        ]
    },
]