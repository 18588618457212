import exportExcel from "@/common/exportExcel"
import showToast from "@/common/showToast"
import _axios from "@/plugins/axios"
import { ref } from "vue"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
){
    // 订单状态 1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败 7:售后
    tableOption.selection = true
    //搜索
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        {label:'用户名称/账号/电话号码',prop:'realName',type:'text'},
        {label:'订单号',prop:'orderNumber',type:'text'},
        {label:'收货人电话',prop:'mobile',type:'text'},
        {label:'订单状态',prop:'status',type:'select',dictType:"order-status"},
        {label:'支付方式',prop:'payType',type:'select',dictType:"pay-type"},
        {label:'支付状态',prop:'isPayed',type:'select',dictType:"pay-status"},
        {label:'专区类型',prop:'regionId',type:'select',multiple:true,options:[],lkey:'regionName',vkey:'id'},
        {label:'下单时间',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
    ]

    pageParams.pageSize=8
    tableRequestOption.url = '/sys/order/page'
	// tableRequestOption.params = {}

    handleEventObj.orderDetail = (item)=>{
        formDialogOption.formTitle = '订单详情'
        formDialogOption.openType = 'custom'
        formDialogOption.item = item
        formDialogOption.dialogWidth = '90%'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'orderDetail'
    }
    // 导出当前
    handleEventObj.exportCurrent = ()=>{
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/order/exportOrderExcel',params)
    }

    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/order/exportOrderExcel',
            fieldOption:[
                { name: '下单用户名称', prop: 'userName', checked: true },
                { name: '下单用户账号', prop: 'account', checked: true },
                { name: '下单电话号码', prop: 'userMobile', checked: true },
                { name: '订单号', prop: 'orderId', checked: true },
                { name: '专区类型', prop: 'regionName', checked: true },
                { name: '支付状态', prop: 'isPayed', checked: true },	
                { name: '订单状态', prop: 'status', checked: true },
                { name: '商品名称', prop: 'prodName', checked: true },
                { name: '订单商品数', prop: 'productNums', checked: true },
                { name: '订单金额', prop: 'actualTotal', checked: true },
                { name: '支付金额', prop: 'payAmount', checked: true },
                { name: '支付方式', prop: 'payType', checked: true },
                { name: '支付时间', prop: 'payTime', checked: true },
                { name: '收货人', prop: 'receiver', checked: true },
                { name: '收货人电话', prop: 'mobile', checked: true },
                { name: '收货地址', prop: 'deliveryAddress', checked: true },
                { name: '下单时间', prop: 'createTime', checked: true },
                { name: '备注', prop: 'remarks', checked: true },
            ]
        }
    }

    // 团队导出
    handleEventObj.exportGroup = ()=>{
        drawerOption.show = true
        drawerOption.title = '团队订单导出'
        drawerOption.size = '30%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'groupExport'
        // drawerOption.item = {
        //     item:item,
        //     searchParams:searchParams.value,
        //     pageParams:pageParams,
        //     url:'/sys/order/exportOrderExcel',
        //     fieldOption:[
        //         { name: '下单用户名称', prop: 'userName', checked: true },
        //         { name: '下单用户账号', prop: 'account', checked: true },
        //         { name: '下单电话号码', prop: 'userMobile', checked: true },
        //         { name: '订单号', prop: 'orderId', checked: true },
        //         { name: '专区类型', prop: 'regionName', checked: true },
        //         { name: '支付状态', prop: 'isPayed', checked: true },	
        //         { name: '订单状态', prop: 'status', checked: true },
        //         { name: '商品名称', prop: 'prodName', checked: true },
        //         { name: '订单商品数', prop: 'productNums', checked: true },
        //         { name: '订单金额', prop: 'actualTotal', checked: true },
        //         { name: '支付金额', prop: 'payAmount', checked: true },
        //         { name: '支付方式', prop: 'payType', checked: true },
        //         { name: '支付时间', prop: 'payTime', checked: true },
        //         { name: '收货地址', prop: 'deliveryAddress', checked: true },
        //         { name: '下单时间', prop: 'createTime', checked: true },
        //     ]
        // }
    }
    // 获取列表选中数据
    const selectRowData = ref([])
    handleEventObj.tableRowSelect = (arr)=>{
        selectRowData.value = arr
    }
    //一键代发
    handleEventObj.behalfOrder = ()=>{
        if(!selectRowData.value.length) return showToast.warning("请选择需要代发的订单")
        formDialogOption.formTitle = '一键代发订单'
        formDialogOption.openType = 'custom'
        formDialogOption.dialogWidth = '30%'
        formDialogOption.item = selectRowData.value
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'behalfOrder'   
    }
    // 导出已售订单
    handleEventObj.exportSealOrder = ()=>{
        // operateInProcess({show:true,title:'正在导出'})
        formDialogOption.formTitle = '导出已售订单'
        formDialogOption.openType = 'custom'
        // formDialogOption.dialogWidth = '1100px'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'exportSealOrder'    
    }
    // 导出发货订单
    handleEventObj.exportSendOrder = ()=>{
        formDialogOption.formTitle = '导出发货订单'
        formDialogOption.openType = 'custom'
        // formDialogOption.dialogWidth = '1100px'
        formDialogOption.showFormDialog = true
        formDialogOption.customCopName = 'exportSendOrder'
    }

    //修改收货地址
    handleEventObj.changeOrderAddr=(item)=>{
        drawerOption.show = true
        drawerOption.title = '修改收货地址'
        drawerOption.size = '30%'
        drawerOption.item = item
        drawerOption.customCopName = 'changeOrderAddr'
    }

    // 获取专区类型
    getSpecialArea()
    async function getSpecialArea(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        searchOptionsConditionQuery.options[6].options = res
    }
}
