export const tableConfig = [
    {
        prop: "userName",
        label: "用户名称",
        fixed:true
    },
    {
        prop: "userMobile",
        label: "手机号码",
    },
    {
        prop: "account",
        label: "账号",
    },
    {
        prop: "regionName",
        label: "专区名称",
    },
    {
        prop: "orderNumber",
        label: "订单号",
    },
    {
        prop: "integralType",
        label: "积分类型",
        type: "state",
        dictType:'integral-style',
    },
    {
        prop: "integralValue",
        label: "积分值",
    },
    {
        prop: "rewardPercent",
        label: "奖励百分比(%)",
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
