export const tableConfig = [
    {
        prop: "userPhone",
        label: "转出方手机号码",
    },
    {
        prop: "userAccount",
        label: "转出方账号",
    },
    {
        prop: "userName",
        label: "转出方名称",
    },
    {
        prop: "toUserPhone",
        label: "接收方手机号码",
    },
    {
        prop: "toUserAccount",
        label: "接收方账号",
    },
    {
        prop: "toUserName",
        label: "接收方名称",
    },
    {
        prop: "amount",
        label: "转账金额",
    },
    {
        prop: "commission",
        label: "手续费扣除的金额",
    },
    {
        prop: "actualAmount",
        label: "实际到账金额",
    },
    {
        prop: "integralType",
        label: "积分类型",
        type: "state",
        dictType:'integral-style'
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]
