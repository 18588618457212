export const tableConfig = [
    {
        prop: "realName",
        label: "分红人",
        fixed:true
    },
    {
        prop: "day",
        label: "期数",
        fixed:true
    },
    {
        prop: "account",
        label: "分红人账号",
    },
    {
        prop: "userMobile",
        label: "分红人手机号",
    },
    {
        prop: "oriValue",
        label: "分红基数",
    },
    {
        prop: "actualValue",
        label: "实际到账数额",
    },
    // {
    //     prop: "distributionTime",
    //     label: "发放时间",
    // },
    {
        prop: "status",
        label: "领取状态",
        type:'state',
        dictType:'shopping-voucher-record-status'
    },
    {
        prop: "createTime",
        label: "创建时间",
    },
]
