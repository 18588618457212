export const tableConfig=[
    {
        prop: "poolPercent",
        label: "资金池百分比(%)",
        width:500
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        dictType:'enable-status'
    }
]

export const formConfig=[
    {
        prop: "poolPercent",
        label: "资金池百分比(%)",
        type:'number',
    },
    {
        prop: "regionId",
        label: "专区类型",
        disabled:true,
        type:"select",
        option:[]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        dictType:'enable-status'
    }
]