export const tableConfig=[
    {
        prop: "account",
	    label: "账号",
    },
    {
		prop: "realName",
		label: "用户姓名",
	},
    {
        prop: "userMobile",
	    label: "手机号码",
    },
	{
	    prop: "levelName",
	    label: "等级名称",
	},
	{
		prop: "levelType",
		label: "等级类型",
        type:'state',
        option:[
            {label:"代理等级",value:1},
            {label:"拼券VIP等级",value:2},
            {label:"会员等级",value:3},
        ]
	},
	
    {
        prop: "operationType",
		label: "操作类型",
        type:'state',
        option:[
            {label:"变更",value:0},
            {label:"删除",value:1},
        ]
    },
    {
        label:'变更前等级名称',
        prop:'beforeLevelName'
    },
    {
        label:'发起人',
        prop:'createBy'
    },
    {
        label:'审核人',
        prop:'updateBy'
    },
    {
        prop: "status",
        label: "审核状态",
        type:"state",
        option:[
            {label:"未审核",value:0},
            {label:"审核成功",value:1},
            {label:"审核失败",value:2},
            // 0 未审核 1审核成功 2 审核失败
        ] 
    },
	{
		prop: "createTime",
		label: "创建时间",
	},
]
