export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    // formOption.labelWidth = 200
    formOption.newApiOption.api = `/sys/poolConditionSet/addOrModify`

    getUserLevel()
    async function getUserLevel(){
        const res = await _axios('post','/sys/userLevel/list',{type:3},true)
        formColumnConfig[2].option = res
        formColumnConfig[2].lkey = 'levelName'
        formColumnConfig[2].vkey = 'levelId'
    }
}
